// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify"
import { SelectedCard } from "./components/ChatbotSidePanelController";
import { QuestionType } from "../../automaticformcreation/src/AutomaticFormCreationController.web";

export const configJSON = require("./config");

const visitorsLandOnSite = "when_visitors_land_on_the_site";

type GeneralSettings = {
  data: {
    id: string;
    type: "setting";
    attributes: {
      practice_association: string[];
      when_should_bot_initiate_chat: {
        mobile: string[];
        desktop: string[];
      };
      response_interval: null | number;
      what_user_action_should_the_bot_allow: {
        device: string[];
      };
      chatbot_url: null | string;
      chatbot_id: number;
      edatt: boolean;
      qr_code: null | string;
    };
  };
};

type DesignSettings= {
  data: {
    id: string;
    type: "customise_interface";
    attributes: {
      id: number;
      color: null | string;
      header_color: string;
      label: string;
      text_size: null | string;
      background_color: string;
      contrast: null | string;
      catalogue_id: null | string;
      widget_type: string;
      practice_association: string[];
      chatbot_name: string;
      widget_url: null | string;
    };
  };
};

export type ConnectorCardLink = {
  id: number;
  link_name: string;
  link_type: string;
  open_link: string;
  icon_file: string | null;
  link_url: string | null;
  destination: string;
  connector_icon_image_link: string | null;
}

type FormQuestion = {
  id: number;
  question: string;
  question_type: QuestionType;
  position: number;
  options: string[];
  bx_block_custom_forms_custom_form_id: number;
  created_at: string;
  updated_at: string;
};

type EmbeddedForm = {
  id: number;
  questions: FormQuestion[];
};

type UserGuide = {
  id: number;
  title: string;
  content: string;
};

export type ChatResponse = {
  id: number;
  message: string;
  video_link: string | null;
  card_type: SelectedCard;
  card_wait_time: number;
  option: string[];
  minimum_selection: number | null;
  maximum_selection: number | null;
  connector_card_links: ConnectorCardLink[];
  embeded_forms: EmbeddedForm[];
  user_guides: UserGuide[];
  video_data: {
    title: string;
    description: string | null;
    thumbnail_url: string;
  } | null;
};

type ChatAttributes = {
  id: number;
  chatbot_id: number;
  query: string | null;
  response: ChatResponse;
};

type ChatData = {
  id: string;
  type: string;
  attributes: ChatAttributes;
};

export type FormQuestionWithAnswer = {
  id: number;
  question: string;
  answer: string;
}

export type ChatMessages = {
  id: number;
  message: string;
  cardType: SelectedCard | "user";
  formQuestionsWithAnswers?: Array<FormQuestionWithAnswer>;
  reset?: boolean;
}

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  initialOpen: boolean;
  isChatOpen: boolean;
  userAction: string[];
  widgetType: string;
  botIcon: string | null;
  chatHeaderLabel: string;
  chatHeaderBackgroundColor:string;
  chatBackgroundColor: string;
  loading: boolean;
  resonseInterval: number;
  botName: string;
  chatLoading: boolean;
  hasErrorInChatResponse: boolean;
  botChatDetailMap: Map<number, ChatResponse>;
  chatMessageLists: ChatMessages[];
  isEdattUser: boolean;
  accessibilityAnchorEl: HTMLDivElement | null;
  status: "active" | "inactive";
  accessibilitySettings: string[];
}

interface SS {
  id: any;
}

export default class EndUserChatBotController extends BlockComponent<
  Props,
  S,
  SS
> {
  chatbotDetailCallId: string = "";
  userChatCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationScreenNameMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      initialOpen: false,
      isChatOpen: false,
      userAction: [],
      widgetType: "chat",
      botIcon: null,
      chatHeaderBackgroundColor: "#51ABB3",
      chatHeaderLabel: "Max Support Chatbot",
      chatBackgroundColor: "#FFFFFF",
      loading: false,
      botName: "",
      resonseInterval: 0,
      chatLoading: false,
      hasErrorInChatResponse: false,
      botChatDetailMap: new Map(),
      chatMessageLists: [],
      isEdattUser: false,
      accessibilityAnchorEl: null,
      status: "inactive",
      accessibilitySettings: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if(message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleRestApiResponse(message)
    }
  }

  handleRestApiResponse = (message: Message) => {
    const responseHandlers: Record<string, (newMsg: Message) => void> = {}
    const messageId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))

    if(this.chatbotDetailCallId === messageId) {
      responseHandlers[this.chatbotDetailCallId] = this.handleChatbotDetailResponse
    }

    if(this.userChatCallId === messageId) {
      responseHandlers[this.userChatCallId] = this.handleInitialUserChatsResponse
    }
    
    const handler = responseHandlers[messageId]
    if(handler) {
      handler(message)
    }
  }

  async componentDidMount() {
    this.setState({loading: true})
    this.fetchChatbotDetails()
  }

  scrollToBottom = () => {
    const container = document.getElementById("chat-popup-container")!
    container.scrollTop = container?.scrollHeight
  }

  fetchChatbotDetails = () => {
    const botId = this.props.navigation.getParam("navigationBarTitleText")
    const reqMsg = new Message(getName(MessageEnum.RestAPIRequestMessage))

    const header = {
      "Content-Type": "application/json",
    }

    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    )

    reqMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.chatbotEndpoint}/${botId}`
    )

    this.chatbotDetailCallId= reqMsg.messageId
    this.send(reqMsg)
  }

  fetchInitialUserChats = () => {
    this.setState({chatLoading: true})

    const reqMsg = new Message(getName(MessageEnum.RestAPIRequestMessage))
    const botId = this.props.navigation.getParam("navigationBarTitleText")

    const header = {
      "Content-Type": "application/json",
    }

    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    )

    reqMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.initialUserChats}?chatbot_id=${botId}`
    )

    this.userChatCallId= reqMsg.messageId
    this.send(reqMsg)
  }

  handleFetchNextCards = (cardId: number, value: string) => {
    const botId = this.props.navigation.getParam("navigationBarTitleText")
    const newMsg = new Message(getName(MessageEnum.RestAPIRequestMessage))
    const header = {
      "Content-Type": "application/json",
    }
    newMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    )
    newMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userChats}?chatbot_id=${botId}&card_id=${cardId}&next_value=${value}`
    )
    newMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    this.userChatCallId = newMsg.messageId
    this.send(newMsg)
  }

  handleChatbotDetailResponse = (message: Message) => {
    const chatbotResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    this.setState({loading: false})
    if(chatbotResponse.data) {
      const generalSettings = chatbotResponse.general_setting as GeneralSettings
      const designSettings = chatbotResponse.design_setting as DesignSettings

      const status: "active" | "inactive" = chatbotResponse.data.attributes.status

      const { edatt, when_should_bot_initiate_chat, what_user_action_should_the_bot_allow, response_interval } = generalSettings.data.attributes

      const {widget_type, widget_url, header_color, background_color, label} = designSettings.data.attributes      
      
      this.setState({
        userAction: what_user_action_should_the_bot_allow.device,
        widgetType: widget_type,
        botIcon: widget_url,
        chatHeaderBackgroundColor: header_color,
        chatBackgroundColor: background_color,
        chatHeaderLabel: label,
        resonseInterval: response_interval ?? 0,
        botName: chatbotResponse.data.attributes.name,
        isEdattUser: edatt,
        status: status
      }, () => {
        this.setHeaderAndBodyBackgroundColor()
        this.initiateBotChat(when_should_bot_initiate_chat)
      })
      
    }
  }

  handleInitialUserChatsResponse = (message: Message) => {
    const chatbotResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    this.setState({chatLoading: false})
    if(!chatbotResponse || chatbotResponse.error) {
      this.setState({hasErrorInChatResponse: true})
      return;
    }

    if(chatbotResponse.errors === "Chatbot is not active.") {
      toast.error(`${this.state.botName} is not active`, {className: "error__toast"})
      return;
    }

    if(chatbotResponse.user_chats) {
      const newBotDetailMap = new Map<number, ChatResponse>(this.state.botChatDetailMap)
      const userChats = chatbotResponse.user_chats as ChatData[]
      const newChatMessages: ChatMessages[] = [...this.state.chatMessageLists]
      userChats.forEach((chat: ChatData) => {
        const {response} = chat.attributes
        const newResponse: ChatResponse =  {
          id: response.id,
          message: response.message,
          video_link: response.video_link,
          card_type: response.card_type,
          card_wait_time: response.card_wait_time,
          option: response.option,
          maximum_selection: response.maximum_selection,
          minimum_selection: response.minimum_selection,
          connector_card_links: response.connector_card_links,
          embeded_forms: response.embeded_forms,
          user_guides: response.user_guides,
          video_data: response.video_data,
        }
        newBotDetailMap.set(response.id, newResponse)
        newChatMessages.push({
          id: response.id,
          message: response.message,
          cardType: response.card_type,
        })
      })

      this.setState({chatMessageLists: newChatMessages, botChatDetailMap: newBotDetailMap}, () => this.scrollToBottom())
    }
  }

  setHeaderAndBodyBackgroundColor = () => {
    const chatPopupElement = document.getElementById("chatMessagesPopupContainer")
    if(chatPopupElement) {
      chatPopupElement.style.setProperty("--header-background", this.state.chatHeaderBackgroundColor)
      chatPopupElement.style.setProperty("--body-background", this.state.chatBackgroundColor)
    }
  }

  fetchUserChats = () => {
    if(this.state.isChatOpen && this.state.chatMessageLists.length === 0) {
      this.fetchInitialUserChats()
    }
  }

  initiateBotChat = (botInitiateChat: GeneralSettings["data"]["attributes"]["when_should_bot_initiate_chat"]) => {
    if(window.innerWidth <= 768) {
      const mobileChatInitiateText = botInitiateChat.mobile[0]
      this.setState({isChatOpen: mobileChatInitiateText === visitorsLandOnSite}, () => this.fetchUserChats())
    } else {
      const desktopChatInitiateText = botInitiateChat.desktop[0]
      this.setState({isChatOpen: desktopChatInitiateText === visitorsLandOnSite}, () => this.fetchUserChats())
    }
  }

  toggleChat = () => {
    const isChatOpen = !this.state.isChatOpen
    this.setState({isChatOpen: isChatOpen}, () => this.fetchUserChats())
  }

  minimiseChatPopup = () => {
    this.setState({isChatOpen: false})
  }

  onUserInputConfirm = (cardId: number, value: string | number | string[]) => {
    const newChatMessagesList: ChatMessages[] = [...this.state.chatMessageLists]
    const message = Array.isArray(value) ? value.join(", ") : `${value}`
    newChatMessagesList[newChatMessagesList.length - 1].reset = false
    newChatMessagesList.push({
      id: Date.now(),
      message: message,
      cardType: "user",
    })
    this.setState({chatMessageLists: newChatMessagesList, chatLoading: true})
    this.handleFetchNextCards(cardId, message)
  }

  onFormSubmitConfirm = (cardId: number, questionsWithAnswer: Array<FormQuestionWithAnswer>) => {
    const newChatMessagesList: ChatMessages[] = [...this.state.chatMessageLists]
    newChatMessagesList[newChatMessagesList.length - 1].reset = false
    newChatMessagesList.push({
      id: Date.now(),
      message: "",
      formQuestionsWithAnswers: questionsWithAnswer,
      cardType: "user"
    })
    this.setState({chatMessageLists: newChatMessagesList, chatLoading: true})
    this.handleFetchNextCards(cardId, "")
  }

  onRetryClick = () => {
    this.setState({chatMessageLists: [], chatLoading: true, hasErrorInChatResponse: false})
    this.fetchInitialUserChats()
  }

  getLastUserCardTypeIndex = (chatMessages: ChatMessages[]) => {
    let lastIndex = -1
    chatMessages.forEach((message, messageIndex) => {
      if(message.cardType === "user") {
        lastIndex = messageIndex
      }
    })
    return lastIndex
  }

  onGoBack = () => {
    const lastUserMessageIndex = this.getLastUserCardTypeIndex(this.state.chatMessageLists)
    const slicedChatMessageLists = this.state.chatMessageLists.slice(0, lastUserMessageIndex).map(message => ({...message, reset: false}))
    const lastUserIndexOfSlicedChatMessagesList = this.getLastUserCardTypeIndex(slicedChatMessageLists)

    for(let messageIndex = lastUserIndexOfSlicedChatMessagesList + 1; messageIndex < slicedChatMessageLists.length; messageIndex++) {
      slicedChatMessageLists[messageIndex] = {
        ...slicedChatMessageLists[messageIndex],
        reset: true
      }
    }

    this.setState({chatMessageLists: [...slicedChatMessageLists]})
  }

  disableGoBackButton = () => {
    const isUserMssageExist = this.state.chatMessageLists.some((chat) => chat.cardType === "user")
    return !isUserMssageExist
  }

  onOpenAccessibilitySettings = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({accessibilityAnchorEl: event.currentTarget})
  }

  onAccessibilitySettingsClose = () => {
    this.setState({accessibilityAnchorEl: null})
  }

  setAccessibilitySettings = (accessibilitySettings: string[]) => {
    this.setState({accessibilitySettings: accessibilitySettings, accessibilityAnchorEl: null})
  }
}
// Customizable Area End
