import React from "react";
import { Line } from "react-chartjs-2";
import { Typography, styled } from "@material-ui/core";
import { LinearChartData as chatsData } from "../Dashboard4Controller";

const chartSetting = {
    width: 500,
    borderRadius: "8px"
};

const data = {
    labels: chatsData.map(label => label.day),
    datasets: [{
        data: chatsData.map(label => label.chats),
        fill: false,
        borderColor: '#51ABB3',
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 0,
        borderWidth: 2,
        fontFamily: "Cairo",
    }]
};

export default function LineChart() {
    return <LinearChartBox>
        <Typography component={"h2"} >Total Chats this week</Typography>
        <Typography component={"h1"} >4,534</Typography>
        <Line data={data} options={{
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: true,
                    backgroundColor: '#F1F5F9',
                    bodyColor: '#F1F5F9',
                    borderColor: '#F1F5F9',
                    borderWidth: 1,
                    titleColor: "#0F172A",
                    titleFont: { weight: 'bold' },
                    callbacks: {
                        labelTextColor: function (context) {
                            return '#0F172A';
                        }
                    }
                },
                datalabels: {
                    display: false,
                },
            }, scales: {
                x: {
                    display: false
                },
                y: {
                    display: false
                }
            }, responsive: true
        }} {...chartSetting} />
    </LinearChartBox>
}

const LinearChartBox = styled("div")({
    display: "flex",
    flexDirection: "column",

    "& h2": {
        color: "#6F96AA",
        fontSize: "14px",
        fontFamily: "Cairo",
        fontWeight: 700,
        lineHeight: "26.24px"
    },

    "& h1": {
        color: "#14172E",
        fontSize: "24px !important",
        fontWeight: 700,
        fontFamily: "Cairo",
        lineHeight: "44.98px"
    }
});