import React, { useRef, useState } from "react";
import {
    makeStyles,
    MenuItem,
    createTheme,
    ThemeProvider,
    Popover,
} from "@material-ui/core";
import {
    MoreVert,
    ControlPointDuplicateRounded,
    EditRounded,
    DeleteOutlineRounded
} from '@material-ui/icons';

type ChatbotMoreActionsMenuButtonProps = {
    id: number;
    navigateToEditPage: any;
    handleDeleteBot: any;
    handleDuplicateBot: any;
}

const menuTheme = createTheme({
    overrides: {
        MuiPopover: {
            paper: {
                minWidth: 0,
                maxWidth: "231px",
                width: 231,
                border: "1px solid #E2E8F0",
                padding: 4,
                boxSizing: "border-box",
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: 8,
            },
            elevation8: {
                boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
            },
        },
        MuiList: {
            padding: {
                padding: 4,
            }
        },
        MuiMenuItem: {
            root: {
                gap: 8,
                padding: "7px 16px",
                fontFamily: "Cairo",
                fontWeight: 400,
                fontSize: 14,
                lineHeight: "22px",
                color: "#0F172A",
            },
        },
        MuiListItem: {
            button: {
                '&:hover': {
                    backgroundColor: "transparent",
                },
            },
        },
        MuiSvgIcon: {
            root: {
                '&.size-18': {
                    height: 18,
                    width: 18
                },
            },
        },
    },
})

const useStyles = makeStyles({
    cardMoreActionsButton: {
        all: "unset",
        padding: 4,
        cursor: "pointer",
        height: 32,
        width: 32,
        borderRadius: 24,
        boxSizing: "border-box",
        color: "#475569",
        '&[aria-haspopup="true"]': {
            backgroundColor: "#0F172A8C",
            color: '#FFFFFF',
        },
    },
})

export default function ChatbotMoreActionsMenuButton({
    id,
    navigateToEditPage,
    handleDeleteBot,
    handleDuplicateBot
}: ChatbotMoreActionsMenuButtonProps) {
    const moreActionsButtonRef = useRef<HTMLButtonElement>(null)
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const classes = useStyles()

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl)

    const isIconDistanceGreaterThan240 = anchorEl
        ? (window.innerHeight - anchorEl.getBoundingClientRect().bottom) > 240
        : false;

    return (
        <ThemeProvider theme={menuTheme} >
            <button
                ref={moreActionsButtonRef}
                aria-controls={`${id}`}
                aria-haspopup={open}
                aria-label={`show more action list of chatbot${id}`}
                className={classes.cardMoreActionsButton}
                onClick={handlePopoverOpen}
            >
                <MoreVert />
            </button>
            <Popover
                open={open}
                id={`${id}`}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: isIconDistanceGreaterThan240 ? 'bottom' : 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: isIconDistanceGreaterThan240 ? 'top' : 'bottom',
                    horizontal: 'right',
                }}
            >
                <MenuItem>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M3.75 2.25H14.25C15.075 2.25 15.75 2.925 15.75 3.75V14.25C15.75 15.075 15.075 15.75 14.25 15.75H3.75C2.925 15.75 2.25 15.075 2.25 14.25V3.75C2.25 2.925 2.925 2.25 3.75 2.25ZM4.5 14.25C4.0875 14.25 3.75 13.9125 3.75 13.5V4.5C3.75 4.0875 4.0875 3.75 4.5 3.75H13.5C13.9125 3.75 14.25 4.0875 14.25 4.5V13.5C14.25 13.9125 13.9125 14.25 13.5 14.25H4.5ZM9 12.75C8.5875 12.75 8.25 12.4125 8.25 12V6C8.25 5.5875 8.5875 5.25 9 5.25C9.4125 5.25 9.75 5.5875 9.75 6V12C9.75 12.4125 9.4125 12.75 9 12.75ZM6 12.75C5.5875 12.75 5.25 12.4125 5.25 12V8.25C5.25 7.8375 5.5875 7.5 6 7.5C6.4125 7.5 6.75 7.8375 6.75 8.25V12C6.75 12.4125 6.4125 12.75 6 12.75ZM11.25 12C11.25 12.4125 11.5875 12.75 12 12.75C12.4125 12.75 12.75 12.4125 12.75 12V10.5C12.75 10.0875 12.4125 9.75 12 9.75C11.5875 9.75 11.25 10.0875 11.25 10.5V12Z" fill="#0F172A" />
                    </svg>
                    Analytics
                </MenuItem>
                <MenuItem onClick={() => {
                    handleDuplicateBot(id)
                    handlePopoverClose()
                }}>
                    <ControlPointDuplicateRounded className="size-18" />
                    Duplicate Bot
                </MenuItem>
                <MenuItem onClick={() => navigateToEditPage(id)}>
                    <EditRounded className="size-18" />
                    Edit Bot
                </MenuItem>
                <MenuItem onClick={() => {
                    handleDeleteBot(id)
                    handlePopoverClose()
                }}>
                    <DeleteOutlineRounded className="size-18" />
                    Delete Bot
                </MenuItem>
            </Popover>
        </ThemeProvider>
    )
}
