// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { TagsType } from "./types";

import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Practice } from "../../../blocks/automaticformcreation/src/AutomaticFormCreationController.web";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}
export interface QAListing {
  id: number;
  type: string;
  attributes: {
    id: number
    question: string;
    last_update: string;
    response: string;
    practice: number[];
    is_global: boolean;
    active: boolean;
    tags: TagsType[];
    forms: any[];
    created_at: string;
    updated_at: string;
    user_guides: any[];
  }
}
interface S {
  isLoading: boolean,
  qaListing: QAListing[];
  pagination: {
    total: number
  };
  token: string;
  showDeleteRowbtn: any;
  rowToDelete: any;
  switchStates: any;
  openPopup: boolean;
  currentPage: number;
  itemsPerPage: number;
  query: string;
  practices: Practice[];
  initialRender: boolean;
  showMenu: boolean;
  anchorEl: any,
  rowToEdit: any,
}

interface SS {
  id: any;
}

export default class QAProjectPortfolioController extends BlockComponent<
  Props,
  S,
  SS
> {
  getSwitchListingApiCallID: string | null = "";
  getDeleteListingApiCallID: string | null = "";
  getQAListingDataId: string | null = "";
  practiceDataId: string | null = "";
  timeout: ReturnType<typeof setTimeout> | undefined = undefined;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      initialRender: false,
      isLoading: false,
      qaListing: [],
      pagination: { total: 0 },
      token: "",
      showDeleteRowbtn: {},
      rowToDelete: null,
      switchStates: {},
      openPopup: false,
      showMenu: false,
      currentPage: 1,
      itemsPerPage: 16,
      rowToEdit: null,
      query: "",
      practices: [],
      anchorEl: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    this.handleResponseForGetQAListing(from, message)
    this.handleResponseForPractices(from, message)
    this.handleResponseForDeleteRow(from, message);
  }

  async componentDidMount() {
    const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
    this.setState({ token, initialRender: true })
    this.handleFetchPracticeData()
    this.getQAListingData();
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    if (prevState.query !== this.state.query) {
      this.timeout = setTimeout(() => { this.getQAListingData(); }, 500)
    }
  }
  handleSearchQuery = (searchQuery: string) => {
    this.setState(() => ({
      query: searchQuery,
      currentPage: 1,
    }))
  }
  getAbbreviatedText = (text: string) => {
    if (text?.length > 20) {
      return text.slice(0, 20).padEnd(23, "...")
    }
    return text
  }
  getPracticeLabel = (value: number[], isGlobal: boolean) => {
    if (isGlobal) {
      return "Global"
    } 

    if(value.length === 0) {
      return "-"
    }

    const practiceIds = new Set(value)
    const selectedPractices = this.state.practices.filter((practice) => practiceIds.has(+practice.value)).map(practice => practice.label)
    return selectedPractices.join(", ")
  }
  getPracticeData = (value: number[]) => {
    const practiceIds = new Set(value)
    const selectedPractices = this.state.practices.filter((practice) => practiceIds.has(+practice.value)).map(practice => practice.label)
    return selectedPractices.join(", ")
  }

  handleDeleteRow(event: any, id: any) {
    this.setState({
      anchorEl: event.currentTarget,
      showMenu: true,
      rowToDelete: id,
      rowToEdit: id
    });
  };

  showPopup = () => {
    this.setState(() => ({
      openPopup: true,
      showMenu: false,
      showDeleteRowbtn: {}
    }))
  }
  handleClose = () => {
    this.setState({
      openPopup: false,
      rowToDelete: null,
    })
  }
  closeMenuPopup = () => {
    this.setState({
      showMenu: false,
      rowToEdit: null
    })
  }
  navigateToCreatePage = () => {
    const msg = new Message(getName(MessageEnum.NavigationQACreateMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Q&ACreate");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  navigateToEditPage = () => {
    const navigationMessage = new Message(getName(MessageEnum.NavigationQAEditMessage))
    navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), { navigation: this.props.navigation })
    navigationMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), this.state.rowToEdit)
    this.send(navigationMessage)
  }
  handlePageChange = (event: any, value: number) => {
    this.setState({ currentPage: value }, () => this.getQAListingData());
  };
  handleSwitchChange = (id: any) => (event: any) => {
    const checked = event.target.checked;
    this.setState((prevState) => ({
      switchStates: {
        ...prevState.switchStates,
        [id]: checked,
      },
      qaListing: prevState.qaListing.map(data => {
        if (data.id === id) {
          return { ...data, attributes: { ...data.attributes, active: checked } }
        }
        return data
      })
    }), () => {
      this.sendSwitchStateToAPI(id, checked);
    });
  };
  getQAListingData = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };

    const newFormDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQAListingDataId = newFormDataMessage.messageId;

    if (this.state.initialRender) {
      this.setState({ isLoading: true })
    }

    newFormDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.qaListingApi}?query=${this.state.query}&page=${this.state.currentPage}&per_page=${this.state.itemsPerPage}`
    );

    newFormDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    newFormDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(newFormDataMessage.id, newFormDataMessage);
  };
  handleResponseForPractices = async (from: string, message: Message) => {
    if (
      this.practiceDataId !== null &&
      this.practiceDataId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.organizations) {
        const newPractices: Practice[] = responseJson.organizations.map((item: { organisation_name: any; id: any; }) => ({ label: item.organisation_name, value: item.id }))
        this.setState({ practices: newPractices })
      }
    }
  }
  handleResponseForGetQAListing = async (from: string, message: Message) => {
    if (
      this.getQAListingDataId !== null &&
      this.getQAListingDataId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        const qaListing = responseJson.data.map((data: QAListing) => ({
          ...data,
          updated_at: this.handleLastUpdate(data.attributes.updated_at)
        }))
        this.setState({ qaListing: qaListing, pagination: responseJson.meta, isLoading: false, initialRender: false });
      } else if (responseJson?.errors?.length > 0 && responseJson?.errors?.[0]?.token) {
        await this.handleLogout()
      }
    }
  }
  handleLastUpdate(date: string) {
    const currentDate = new Date();
    const updatedAt = new Date(date);
    if (updatedAt.toLocaleDateString() === currentDate.toLocaleDateString()) {
      return this.handleFormatTimeString(updatedAt)
    } else if (currentDate.getDay() - updatedAt.getDay() === 1) {
      return `Yesterday ${this.handleFormatTimeString(updatedAt)}`
    } else {
      return `${this.handleFormatDateString(updatedAt)} ${this.handleFormatTimeString(updatedAt)}`
    }
  }
  handleFormatDateString(date: Date) {
    return date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear()
  }
  handleFormatTimeString(date: Date) {
    const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };
    const formattedTime = date.toLocaleTimeString('en-US', options);
    return formattedTime; // Output: 05:24 AM
  }
  async handleLogout() {
    await removeStorageData("authToken")
    await removeStorageData("userId")
    sessionStorage.clear()
    const message: Message = new Message(
      getName(MessageEnum.NavigationSignupLoginMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  sendSwitchStateToAPI = async (id: any, checked: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const httpBody = {
      active: checked
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSwitchListingApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.qaListingApi}/${id}/update_active_status`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deleteFormData = async () => {
    const header = {
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDeleteListingApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.qaListingApi}/${this.state.rowToDelete}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleResponseForDeleteRow = (from: string, message: Message) => {
    if (
      this.getDeleteListingApiCallID !== null &&
      this.getDeleteListingApiCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message) {
        let page = this.state.currentPage
        if (this.state.qaListing.length === 1 && this.state.currentPage > 1) {
          page = this.state.currentPage - 1
        }
        this.setState({
          openPopup: false,
          showDeleteRowbtn: false,
          currentPage: page,
        })
        toast.success("Q&A successfully deleted", { className: "success__toast" })
        this.getQAListingData()
      }
    }
  }
  handleFetchPracticeData() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const newRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    newRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    newRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPracticeAPIEndPoint
    )
    newRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod)
    this.practiceDataId = newRequestMessage.messageId;
    runEngine.sendMessage(newRequestMessage.id, newRequestMessage);
  }

}

// Customizable Area End
