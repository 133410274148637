// Customizable Area Start
import React from "react";
import { styled, Button, Divider, FormLabel, OutlinedInput, Popover, ThemeProvider, Typography, createTheme } from "@material-ui/core";
import { AddRounded, CheckRounded } from "@material-ui/icons";
import { HexColorInput, HexColorPicker } from "react-colorful";
import CustomSelect from "../../../../components/src/CustomSelect.web";
import ColorPickerController, { ColorPickerProps } from "./ColorPickerController";

const theme = createTheme({
    typography: {
        h3: {
            fontFamily: "Cairo",
            fontWeight: 700,
            fontSize: 16,
            lineHeight: "24px",
            color: "#0F172A",
        },
    },
    overrides: {
        MuiDivider: {
            root: {
                backgroundColor: "#CBD5E1"
            },
            vertical: {
                height: 22,
            },
        },
        MuiFormLabel: {
            root: {
                fontFamily: "Inter",
                fontWeight: 700,
                fontSize: 14,
                lineHeight: "22px",
                color: "#64748B"
            },
        },
        MuiButton: {
            root: {
                borderRadius: 8,
                textTransform: "none",
                flex: 1,
                padding: "6px 10px",
                '&:hover': {
                    backgroundColor: "transparent",
                    boxShadow: 'none',
                },
                '&.Mui-secondary': {
                    backgroundColor: "#E5F6FF",
                    color: "#51ABB3",
                },
                '&.Mui-secondary:hover': {
                    backgroundColor: "#E5F6FF",
                    boxShadow: 'none',
                },
                '&.Mui-primary': {
                    color: "#FFFFFF",
                    backgroundColor: "#51ABB3",
                },
                '&.Mui-primary:hover': {
                    backgroundColor: "#51ABB3",
                },
            },
            label: {
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "24px",
                fontFamily: "Inter",
            },
        },
        MuiOutlinedInput: {
            notchedOutline: {
                borderWidth: 1,
                borderColor: "#CBD5E1",
            },
            root: {
                borderRadius: 8,
                '&:hover $notchedOutline': {
                    borderColor: "#CBD5E1",
                },
                '&.Mui-Focused $notchedOutline': {
                    borderColor: "#CBD5E1",
                    borderWidth: 1,
                },
            },
            input: {
                padding: 8,
            },
        },
        MuiInputBase: {
            input: {
                color: "#64748B",
                fontFamily: "Inter",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
                height: "auto",
                '&[type="number"]': {
                    '-moz-appearance': 'textfield',
                },
                '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                    "-webkit-appearance": "none",
                },
            },
        },
        MuiPaper: {
            elevation8: {
                boxShadow: "0px 2px 8px 0px #00000014",
            },
            rounded: {
                borderRadius: 8,
            },
        },
    },
})

const Container = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 12,
});

const CircleBtn = styled("button")({
    all: "unset",
    cursor: "pointer",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 2px 8px 0px #00000014",
    border: "4px solid #FFFFFF",
    width: 40,
    height: 40,
    borderRadius: 100,
});

const ColorPickerContainer = styled("div")({
    display: "flex",
    gap: 12,
    alignItems: "center",
});

const ColorPickerDilogContent = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 12,
    padding: 24,
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
});

const Column = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 8,
});

const Row = styled("div")({
    display: "flex",
    gap: 8,
    '& .colorSelect': {
        padding: 8,
        gap: 16,
    },
});

const StyledColorPicker = styled("div")({
    position: "relative",
    '& .react-colorful': {
        width: "100%",
        height: 116,
    },
    '& .react-colorful__saturation': {
        marginBottom: 4,
        height: 88,
        border: "2px solid #FFFFFF",
        borderRadius: 6,
        boxShadow: "0px 2px 8px 0px #00000014",
    },
    '& .react-colorful__hue': {
        width: "85%",
        border: "2px solid #FFFFFF",
        borderRadius: 20,
        boxShadow: "0px 2px 8px 0px #00000014",
    },
    '& .react-colorful__hue-pointer': {
        boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
        borderRadius: 10,
        width: 4.5,
        height: 24,
    },
    '& .react-colorful__saturation-pointer': {
        width: 16,
        height: 16,
        borderRadius: 100,
    },
});

const CircleColor = styled("div")({
    position: "absolute",
    bottom: 0,
    right: 0,
    border: "2px solid #FFFFFF",
    height: 24,
    width: 24,
    borderRadius: 100,
    boxShadow: "0px 2px 8px 0px #00000014",
});

const Option = styled("span")({
    display: "inline-block",
    boxSizing: "border-box",
    height: 24,
    width: 24,
    border: "2px solid #FFFFFF",
    borderRadius: 100,
    boxShadow: "0px 2px 8px 0px #00000014",
});


const SelectedColorOption = styled("div")({
    width: 104,
    padding: 8,
    borderRadius: 8,
    border: "1px solid #CBD5E1",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "24px",
    color: "#64748B",
});

export default class ColorPicker extends ColorPickerController {

    constructor(props: ColorPickerProps) {
        super(props)
    }

    render() {

        return (
            <ThemeProvider theme={theme} >
                <Container>
                    <Typography variant="h3">{this.props.title}</Typography>
                    <ColorPickerContainer>
                        {
                            this.props.colors.map((color) => (
                                <CircleBtn
                                    key={color}
                                    aria-label={`Select color ${color}`}
                                    data-color={color}
                                    aria-selected={this.props.selectedColor === color}
                                    onClick={() => this.props.handleColorChange(color)}
                                    style={{ backgroundColor: color }}
                                >
                                    {this.props.selectedColor === color && <CheckRounded htmlColor={this.props.iconColor} />}
                                </CircleBtn>
                            ))
                        }
                        <Divider orientation="vertical" />
                        <CircleBtn
                            aria-label="Open color picker"
                            ref={this.colorBtnRef}
                            onClick={this.handleOpenColorPicker}
                            style={{ backgroundColor: this.props.selectedColor }}
                        >
                            <AddRounded htmlColor={this.props.iconColor} />
                        </CircleBtn>
                        <Popover
                            id={"popover" + "-" + this.props.type}
                            open={this.state.open}
                            anchorEl={this.colorBtnRef.current}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            onClose={this.handleCloseColorPicker}
                            PaperProps={{ style: { width: 294, boxShadow: "none", backgroundColor: "transparent" } }}
                        >
                            <ColorPickerDilogContent>
                                <Column>
                                    <FormLabel htmlFor="select-color" >Select color</FormLabel>
                                    <Row>
                                        <CustomSelect
                                            id="color-select"
                                            labelId="select"
                                            value={this.state.optionColor}
                                            placeholder=""
                                            options={this.colorOptions}
                                            selectClassName="colorSelect"
                                            optionClassName="colorOption"
                                            onChange={this.handleOptionChange}
                                            selectBoxWidth={80}
                                            renderOption={(option) => <Option style={{ backgroundColor: option.value }} />}
                                            renderSelectedValue={(option) => <Option style={{ backgroundColor: option.value }} />}
                                        />
                                        <SelectedColorOption>{this.state.optionColor}</SelectedColorOption>
                                    </Row>
                                </Column>
                                <StyledColorPicker>
                                    <HexColorPicker
                                        color={this.state.hex}
                                        onChange={this.handleHexColorChange}
                                    />
                                    <CircleColor style={{ backgroundColor: this.state.hex }} />
                                </StyledColorPicker>
                                <Row>
                                    <Column>
                                        <FormLabel htmlFor="hex">Hex</FormLabel>
                                        <OutlinedInput
                                            type="text"
                                            id="hex"
                                            fullWidth
                                            style={{ width: 84 }}
                                            inputComponent={(props) => (
                                                <HexColorInput
                                                    {...props}
                                                    color={this.state.hex}
                                                    onChange={this.handleHexColorChange}
                                                    prefixed
                                                    name="hex"
                                                />
                                            )}
                                        />
                                    </Column>
                                    <Column>
                                        <FormLabel htmlFor="r">R</FormLabel>
                                        <OutlinedInput
                                            type="number"
                                            id="r"
                                            fullWidth
                                            name="r"
                                            value={this.state.rgb.r}
                                            onChange={this.handleRGBChange}
                                            inputProps={{ min: 0, max: 255 }}
                                        />
                                    </Column>
                                    <Column>
                                        <FormLabel htmlFor="g">G</FormLabel>
                                        <OutlinedInput
                                            type="number"
                                            id="g"
                                            fullWidth
                                            name="g"
                                            value={this.state.rgb.g}
                                            onChange={this.handleRGBChange}
                                            inputProps={{ min: 0, max: 255 }}
                                        />
                                    </Column>
                                    <Column>
                                        <FormLabel htmlFor="b">B</FormLabel>
                                        <OutlinedInput
                                            type="number"
                                            id="b"
                                            fullWidth
                                            name="b"
                                            value={this.state.rgb.b}
                                            onChange={this.handleRGBChange}
                                            inputProps={{ min: 0, max: 255 }}
                                        />
                                    </Column>
                                </Row>
                                <Row style={{ marginTop: 12 }} >
                                    <Button
                                        variant="contained"
                                        disableElevation
                                        className="Mui-secondary"
                                        onClick={this.handleCloseColorPicker}
                                        id="cancel"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        disableElevation
                                        className="Mui-primary"
                                        onClick={this.handleApplyColorChange}
                                        id="apply"
                                    >
                                        Done
                                    </Button>
                                </Row>
                            </ColorPickerDilogContent>
                        </Popover>
                    </ColorPickerContainer>
                </Container>
            </ThemeProvider>
        )
    }
}
// Customizable Area End
