import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import DataContext from "./components/DataContext";
import { AutomaticField } from "./types";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { Practice } from "./AutomaticFormCreationController.web";
import { Organization } from "./AutomaticEditFormController";

interface FormListType {
  id: number,
  type: string,
  attributes: {
    form_name: string,
    active: boolean,
    last_update: string,
    updated_at: string,
    practice: string[] | null,
    is_global: boolean,
    first_name: string,
    last_name: string
  }
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean,
  formData: Array<AutomaticField>;
  pagination: {
    total: number
  };
  token: string;
  showDeleteRowbtn: any;
  rowToDelete: any;
  switchStates: any;
  openPopup: boolean;
  currentPage: number;
  itemsPerPage: number;
  query: string;
  practices: Practice[];
  initialRender: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AutomaticFormLandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  static contextType: React.Context<AutomaticField> = DataContext;
  getFormDataId: string = "";
  getDeleteDataFormApiCallID: string = "";
  getUpdateDataFormApiCallID: string = "";
  getSwitchFormApiCallID: string = "";
  practiceDataId: string = "";
  timeout: ReturnType<typeof setTimeout> | undefined = undefined;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseToken)
    ];

    this.state = {
      // Customizable Area Start
      initialRender: false,
      isLoading: false,
      formData: [],
      pagination: { total: 0 },
      token: "",
      showDeleteRowbtn: {},
      rowToDelete: null,
      switchStates: {},
      openPopup: false,
      currentPage: 1,
      itemsPerPage: 16,
      query: "",
      practices: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    this.handleResponseForGetFormData(from, message);
    this.handleResponseForActiveStatus(from, message);
    this.handleResponseForDeleteRow(from, message);
    this.handleResponseForPractices(from, message)
    // Customizable Area End
  }

  // Customizable Area Start
  handleResponseForGetFormData = async (from: string, message: Message) => {
    if (
      this.getFormDataId !== null &&
      this.getFormDataId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        const allFormData = responseJson.data.map((data: FormListType) => ({
          ...data,
          updated_at: this.handleLastUpdate(data.attributes.updated_at)
        }))
        this.setState({ formData: allFormData, pagination: responseJson.meta, isLoading: false, initialRender: false });
      } else if (responseJson?.errors?.length > 0 && responseJson?.errors?.[0]?.token) {
        await this.handleLogout()
      }
    }
  }
  handleResponseForPractices = async (from: string, message: Message) => {
    if (
      this.practiceDataId !== null &&
      this.practiceDataId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.organizations) {
        const newPractices: Practice[] = responseJson.organizations.map((item: Organization) => ({ label: item.organisation_name, value: item.id }))
        this.setState({ practices: newPractices })
      }
    }
  }
  handleResponseForActiveStatus = (from: string, message: Message) => {
    if (
      this.getSwitchFormApiCallID !== null &&
      this.getSwitchFormApiCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        const newFormData = this.state.formData.map((data) => {
          if (data.id === responseJson.data.id) {
            const updatedData = { ...responseJson.data, updated_at: this.handleLastUpdate(responseJson.data.attributes.updated_at) }
            return updatedData
          }
          return data
        })
        this.setState(() => ({
          formData: newFormData
        }))
      }
    }
  }
  handleResponseForDeleteRow = (from: string, message: Message) => {
    if (
      this.getDeleteDataFormApiCallID !== null &&
      this.getDeleteDataFormApiCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message) {
        let page = this.state.currentPage
        if (this.state.formData.length === 1 && this.state.currentPage > 1) {
          page = this.state.currentPage - 1
        }
        this.setState({
          openPopup: false,
          showDeleteRowbtn: false,
          currentPage: page
        })
        toast.success("Form successfully deleted", { className: "success__toast" })
        this.getWebFormData()
      }
    }
  }

  handleLastUpdate(date: string) {
    const currentDate = new Date();
    const updatedAt = new Date(date);
    if (updatedAt.toLocaleDateString() === currentDate.toLocaleDateString()) {
      return this.handleFormatTimeString(updatedAt)
    } else if (currentDate.getDay() - updatedAt.getDay() === 1) {
      return `Yesterday ${this.handleFormatTimeString(updatedAt)}`
    } else {
      return `${this.handleFormatDateString(updatedAt)} ${this.handleFormatTimeString(updatedAt)}`
    }
  }
  handleFormatDateString(date: Date) {
    return date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear()
  }

  handleFormatTimeString(date: Date) {
    const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };
    const formattedTime = date.toLocaleTimeString('en-US', options);
    return formattedTime; // Output: 05:24 AM
  }

  async handleLogout() {
    await removeStorageData("authToken")
    await removeStorageData("userId")
    sessionStorage.clear()
    const message: Message = new Message(
      getName(MessageEnum.NavigationSignupLoginMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleDeleteRow = (id: any) => () => {
    this.setState((prevState) => ({
      showDeleteRowbtn: {
        [id]: !prevState.showDeleteRowbtn[id],
      },
      rowToDelete: id,
    }));
  };

  showPopup = () => {
    this.setState(() => ({
      openPopup: true,
      showDeleteRowbtn: {}
    }))
  }
  handleClose = () => {
    this.setState({
      openPopup: false,
      rowToDelete: null,
    })
  }
  handlePageChange = (event: any, value: number) => {
    this.setState({ currentPage: value }, () => this.getWebFormData());
  };

  handleSwitchChange = (id: any) => (event: any) => {
    const checked = event.target.checked;
    this.setState((prevState) => ({
      switchStates: {
        ...prevState.switchStates,
        [id]: checked,
      },
      formData: prevState.formData.map(data => {
        if (data.id === id) {
          return { ...data, attributes: { ...data.attributes, active: checked } }
        }
        return data
      })
    }), () => {
      this.sendSwitchStateToAPI(id, checked);
    });
  };

  handleSearchQuery = (searchQuery: string) => {
    this.setState(() => ({
      query: searchQuery,
      currentPage: 1,
    }))
  }

  async componentDidMount() {
    const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
    this.setState({ token, initialRender: true })
    this.getWebFormData();
    this.handleFetchPracticeData()
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    if (prevState.query !== this.state.query) {
      this.timeout = setTimeout(() => { this.getWebFormData(); }, 500)
    }
  }

  handleCreateNavigate = () => {
    const msg = new Message(getName(MessageEnum.NavigationAutomaticFormCreationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "AutomaticFormCreation");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleEditNavigate = (id: number) => {
    const navigationMessage = new Message(getName(MessageEnum.NavigationAutomaticEditFormMessage))
    navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), { navigation: this.props.navigation })
    navigationMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), id)
    this.send(navigationMessage)
  }

  getPracticeLabel = (value: string, isGlobal: boolean) => {
    if (isGlobal) {
      return "Global"
    } else if (value) {
      const practiceValues = value.split(",").map(value => Number(value))
      const allpractice = this.state.practices.filter(item => practiceValues.includes(item.value)).map(value=> value.label)
      return allpractice.join(", ")
    }
    return "-";
  }
  getFullPracticeLabel = (value: string) => {
    if(value){
      const practiceValues = value.split(",").map(value => Number(value))
      return this.state.practices.filter(item => practiceValues.includes(item.value)).map(value=> value.label).join(", ")
    }
  }
  getFullPracticeLabellength = (value: string) => {
    if(value){
      const practiceValues = value.split(",").map(value => Number(value))
      const practices = this.state.practices.filter(item => practiceValues.includes(item.value))
      if(practices.length > 2) return false
    }
    return true
  }

  getAbbreviatedText = (text: string) => {
    if (text?.length > 20) {
      return text.slice(0, 20).padEnd(23, "...")
    }
    return text
  }

  getWebFormData = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };

    const newFormDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFormDataId = newFormDataMessage.messageId;

    if (this.state.initialRender) {
      this.setState({ isLoading: true })
    }

    newFormDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFeedDataEndPoint}?query=${this.state.query}&page=${this.state.currentPage}&per_page=${this.state.itemsPerPage}`
    );

    newFormDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    newFormDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(newFormDataMessage.id, newFormDataMessage);
  };

  sendSwitchStateToAPI = async (id: any, checked: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const httpBody = {
      active: checked
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSwitchFormApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.putFormDataEndPoint}/${id}/active_toggle`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putFormMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteFormData = async () => {
    const header = {
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDeleteDataFormApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteFormDataEndPoint}/${this.state.rowToDelete}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteFormMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleFetchPracticeData() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const newRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    newRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    newRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPracticeData
    )
    newRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    )
    this.practiceDataId = newRequestMessage.messageId;
    runEngine.sendMessage(newRequestMessage.id, newRequestMessage);
  }

  // Customizable Area End
}
