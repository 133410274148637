// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import { getStorageData } from "../../../framework/src/Utilities";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { FileListType } from "./types";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}
const selectedFile = [{
  id: 1, ischecked: true, file: "all_files",
  title: "all files"
}, {
  id: 2, ischecked: false, file: "user_guides",
  title: 'user guides'
}, {
  id: 3, ischecked: false, file: "forms",
  title: "forms"
}, {
  id: 4, ischecked: false, file: "que_and_answer",
  title: "Q&As"
}, {
  id: 5, ischecked: false, file: "sign_postings",
  title: "signposting"
}];

interface S {
  projectName: string;
  projectDescription: string;
  clientDetails: string;
  teamSize: string;
  projectUrl: string;

  token: string;
  startDate: string;
  endDate: string;
  images: { attributes: { public_url: string } }[];
  documents: { attributes: { file_name: string; public_url: string } }[];
  email: string;
  fullName: string;
  phoneNumber: string;
  loading: boolean;
  checkedList: FileListType[];
  selectedFileType: { id: number, ischecked: boolean, file: string, title: string }[];
  associatedData: { id: number, file_name: string, user_name: string, profile_image: string | null, flag: string } | null,
  pagination: {
    total: number,
  };
  currentPage: number,
  itemsPerPage: number,
  file_type: string,
  search: string,
  file_sizes: any
}

interface SS {
  id: any;
}

export default class ProjectPortfolioDetailController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      token: "",
      projectName: "",
      projectDescription: "",
      clientDetails: "",
      teamSize: "",
      projectUrl: "",
      startDate: "",
      endDate: "",
      images: [],
      documents: [],
      email: "",
      fullName: "",
      phoneNumber: "",
      loading: false,
      checkedList: [],
      selectedFileType: selectedFile,
      associatedData: null,
      pagination: { total: 0 },
      currentPage: 1,
      itemsPerPage: 16,
      file_type: "all_files",
      search: "",
      file_sizes: null
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }
  apiGetProjectbyIDListCallId: string | null = "";
  getQAFileTypeId: string | null = "";
  saveQAFileTypeId: string | null = "";
  getFileSizeId: string | null = "";

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const responseData = message.getData(
        //getName(MessageEnum.PropsData)
        "Dummy text because Enum does not exist");
      this.getProjectbyID(responseData);
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId !== null) {
        if (apiRequestCallId === this.apiGetProjectbyIDListCallId) {
          const { data } = responseJson.data;
          if (responseJson && responseJson.data) {
            this.setState({
              projectName: data.attributes.project_name,
              projectDescription:
                data.attributes.project.data.attributes.description,
              email: data.attributes.client_account.email,
              fullName: data.attributes.client_account.full_name,
              phoneNumber: data.attributes.client_account.full_phone_number,
              clientDetails: data.attributes.client_details,
              teamSize: JSON.stringify(data.attributes.team_size),
              projectUrl: data.attributes.project.data.attributes.url,
              startDate: data.attributes.project.data.attributes.start_date,
              endDate: data.attributes.project.data.attributes.end_date,
              images: data.attributes.images.data,
              documents: data.attributes.documents.data,
              loading: false,
            });
          } else {
            this.setState({ loading: false });
            this.showAlert("Error", responseJson.errors);
          }
        }
      }
    }
    this.handleResponseForGetFileChange(message)
    this.handleResponseForGetFileSizeData(message)
  }

  async componentDidMount() {
    const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
    this.setState({ token })
    this.getFileChange()
    this.getFileSizeData()
  }
  getProjectbyID = async (projectID: string) => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProjectbyIDListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewProjectsAPIEndPoint + projectID
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  goBack() {
    this.props.navigation.goBack();
  }
  handleNavigate = (redirectTo: "user-guides" | "forms" | "qa" | "signposting") => {

    let navigationMessage = new Message(getName(MessageEnum.NavigationBlogpostsManagementMessage))
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      { navigation: this.props.navigation }
    )
    if (redirectTo === "forms") {
      navigationMessage = new Message(getName(MessageEnum.NavigationFormsMessage))
      navigationMessage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        { navigation: this.props.navigation })
    }
    else if (redirectTo === "qa") {
      navigationMessage = new Message(getName(MessageEnum.NavigationQAMessage))
      navigationMessage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        { navigation: this.props.navigation })
    }
    else if (redirectTo === "signposting") {
      navigationMessage = new Message(getName(MessageEnum.NavigationSignpostingMessage))
      navigationMessage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        { navigation: this.props.navigation })
    }
    this.send(navigationMessage)
  }
  handleSearch = (event: { target: { value: any; }; }) => {
    const text = event.target.value
    this.setState({ search: text }, this.getFileChange);
  }
  handleCheckedList = (id: number) => {
    const newCheckList = this.state.checkedList.map(list => {
      if (list.id === id) {
        return { ...list, ischecked: !list.ischecked }
      }
      return { ...list, ischecked: false }
    })
    this.setState(() => ({
      checkedList: newCheckList
    }))
  }
  handleSwitchChange = (id: number) => {
    const selectedFileType = this.state.selectedFileType.map(file => {
      if (file.id === id) {
        return { ...file, ischecked: true }
      }
      else return { ...file, ischecked: false }
    }) as { id: number, ischecked: boolean, file: string, title: string }[]
    const file = this.state.selectedFileType.find(file => file.id === id)
    this.setState({ selectedFileType: selectedFileType, file_type: file?.file || '', currentPage: 1, search: "" }, () => this.getFileChange())
  }
  handlePageChange = (event: any, value: number) => {
    this.setState({ currentPage: value }, () => this.getFileChange());
  };
  getFileChange = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getQAFileTypeId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.qaListingApi}/${this.state.file_type}?keyword=${this.state.search}&page=${this.state.currentPage}&per_page=${this.state.itemsPerPage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleResponseForGetFileChange = (message: Message) => {
    if (
      this.getQAFileTypeId !== null &&
      this.getQAFileTypeId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.all_files || responseJson?.user_guides || responseJson?.forms || responseJson?.sign_postings || responseJson?.que_and_answer) {
        const response = responseJson?.all_files || responseJson?.user_guides || responseJson?.forms || responseJson?.sign_postings || responseJson?.que_and_answer
        const files = response.map((list: { id: any; flag: any; name: any; data_id: any; updated_at: string; last_update: string }) => ({ id: list.id, fileType: list.flag, ischecked: false, title: list?.name, data_id: list.data_id, last_update: list?.last_update, updated_at: this.handleLastUpdate(list.updated_at) }))
        this.setState({ checkedList: files, pagination: { total: responseJson.meta.total }, currentPage: responseJson.meta.page })
      } else {
        toast.error(responseJson?.error || "Couldn't fetch data, please try again", { className: "error__toast" })
      }
    }
  }
  handleLastUpdate(date: string) {
    const currentDate = new Date();
    const updatedAt = new Date(date);
    const minutesDiff = Math.floor((currentDate.getTime() - updatedAt.getTime()) / (1000 * 60));
    if (minutesDiff < 1) {
      return "Just now";
    } else if (minutesDiff < 60) {
      return `${minutesDiff} min ago`;
    } else if (updatedAt.toLocaleDateString() === currentDate.toLocaleDateString()) {
      return this.handleFormatTimeString(updatedAt)
    } else if (currentDate.getDay() - updatedAt.getDay() === 1) {
      return `Yesterday ${this.handleFormatTimeString(updatedAt)}`
    } else {
      return `${this.handleFormatDateString(updatedAt)} ${this.handleFormatTimeString(updatedAt)}`
    }
  }
  handleFormatDateString(date: Date) {
    return date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear()
  }
  handleFormatTimeString(date: Date) {
    const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };
    const formattedTime = date.toLocaleTimeString('en-US', options);
    return formattedTime; // Output: 05:24 AM
  }
  saveFileChange = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveQAFileTypeId = requestMessage.messageId;
    const id = this.state.checkedList.find(list => list.ischecked === true)?.id
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.qaListingApi}/${id}/show_file`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleResponseForSaveFileChange = (message: Message) => {
    if (
      this.saveQAFileTypeId !== null &&
      this.saveQAFileTypeId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.data) {
        if (responseJson.file_type === "form") {
          this.setState({ associatedData: { id: responseJson.data.id, flag: responseJson.file_type, file_name: responseJson.data.form_name, user_name: `${responseJson?.first_name} ${responseJson?.last_name}`, profile_image: responseJson.profile_image } })
        }
        else if (responseJson.file_type === "user_guide") {
          this.setState({ associatedData: { id: responseJson.data.id, flag: responseJson.file_type, file_name: responseJson.data.title, user_name: `${responseJson?.first_name} ${responseJson?.last_name}`, profile_image: responseJson.profile_image } })
        }
        else if (responseJson.file_type === "sign_posting") {
          this.setState({ associatedData: { id: responseJson.data.id, flag: responseJson.file_type, file_name: responseJson.data.organisation_name, user_name: `${responseJson?.first_name} ${responseJson?.last_name}`, profile_image: responseJson.profile_image } })
        }
      } else {
        toast.error(responseJson?.error || "Couldn't upload data, please try again", { className: "error__toast" })
      }
    }
  }
  getFileSizeData = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFileSizeId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFileSizeEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleResponseForGetFileSizeData = (message: Message) => {
    if (
      this.getFileSizeId !== null &&
      this.getFileSizeId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.data) {
        this.setState({ file_sizes: responseJson.data })
      } else {
        toast.error(responseJson?.error || "Couldn't fetch data, please try again", { className: "error__toast" })
      }
    }
  }
}

// Customizable Area End
