import React, { useEffect, useState } from "react";
import { makeStyles, Popover } from "@material-ui/core"
import { ExpandMoreRounded, SearchRounded } from "@material-ui/icons"
import GenericError from "./GenericError.web";


type MultiSelectCheckboxProps = {
    label: string;
    placeholder: string;
    value: number[];
    options: { label: string, value: number }[];
    hasSearch?: boolean;
    searchPlaceholder?: string;
    onApply: (value: number[]) => void;
    disabled?: boolean;
    error?: string;
    selectBoxWidth?: number;
    handleCloseError?: () => void;
    showError?: boolean;
}

const paperPropsStyle: React.CSSProperties = {
    borderRadius: 8,
    overflow: "hidden",
    maxHeight: "none",
    boxSizing: "border-box",
    border: "1px solid #CBD5E1",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 8px 0px #00000014",
}

const useStyles = makeStyles({
    root: {
        position: "relative",
    },
    selectControl: {
        display: "flex",
        flexDirection: "column",
        gap: 8,
        '& label': {
            fontFamily: "Cairo",
            fontWeight: 700,
            fontSize: 14,
            lineHeight: "22px",
            color: "#475569",
        },
        '& .error': {
            color: "#D97706",
            fontSize: 12,
            fontFamily: "Cairo",
            fontWeight: 400,
            lineHeight: "18px",
        },
    },
    combobox: {
        cursor: "pointer",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontFamily: "Cairo",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "24px",
        color: "#64748B",
        backgroundColor: "#FFFFFF",
        border: "1px solid #CBD5E1",
        borderRadius: 8,
        padding: "16px 8px",
        '&:focus': {
            outline: 0,
        },
        '&[aria-disabled="true"]': {
            pointerEvents: "none",
        },
        '&[data-error="true"]': {
            borderColor: "#F59E0B",
        },
    },
    searchBoxContainer: {
        padding: "8px 16px",
    },
    searchBoxInnerContainer: {
        border: "1px solid #CBD5E1",
        display: "flex",
        gap: 10,
        alignItems: "center",
        borderRadius: 98,
        padding: 6,
    },
    searchInput: {
        all: "unset",
        fontFamily: "Inter",
        color: "#64748B",
        fontSize: 16,
        fontWeight: 400,
        height: "24px",
        lineHeight: "24px",
    },
    listbox: {
        listStyle: "none",
        margin: 0,
        padding: 0,
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        maxHeight: 143,
        scrollbarColor: "#64748B #FFFFFF",
        scrollbarWidth: "thin",
    },
    listOption: {
        margin: 0,
        padding: "12px 16px",
        display: "flex",
        gap: 8,
        alignItems: "center",
        fontFamily: "Inter",
        fontWeight: 400,
        color: "#0F172A",
        lineHeight: "22px",
        fontSize: 14,
        cursor: "pointer",
        '&:hover': {
            backgroundColor: "#F1F5F9",
        },
    },
    actionContainer: {
        padding: 12,
        display: "flex",
        justifyContent: "flex-end",
        gap: 8,
        '& button': {
            all: "unset",
            cursor: "pointer",
            borderRadius: 8,
            fontFamily: "Inter",
            fontWeight: 700,
            fontSize: 16,
            lineHeight: "24px",
        },
        '& .cancel': {
            padding: "8px 10px",
            backgroundColor: "#F8FAFC",
            color: "#51ABB3",
        },
        '& .apply': {
            padding: "8px 12px",
            color: "#64748B",
            backgroundColor: "#F1F5F9",
        },
    },
})

export default function MultiSelectCheckbox({
    label,
    value,
    placeholder,
    options,
    hasSearch = false,
    searchPlaceholder = "Search...",
    onApply,
    disabled=false,
    error,
    selectBoxWidth = 336,
    handleCloseError,
    showError,
}: MultiSelectCheckboxProps) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [selectOptions, setSelectOptions] = useState(options)
    const [selectedValue, setSelectedValue] = useState<number[]>([])
    const classes = useStyles()

    useEffect(() => {
        let newValue = value ?? []
        if(newValue.length === 0) {
            setSelectedValue([])
        } else {
            setSelectedValue(newValue)
        }
    },[value])

    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClosePopover = () => {
        setAnchorEl(null)
        setSelectOptions(options)
        setSelectedValue(value)
    }

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchText = event.target.value;
        if(searchText.trim().length === 0) {
            setSelectOptions(options)
            return;
        }

        const newSelectOptions = selectOptions.filter((option) => {
            return option.label.toLowerCase().includes(searchText.toLowerCase())
        })
        setSelectOptions(newSelectOptions)
    }

    const handleApplyOptionsChange = () => {
        if(selectedValue.length === 0) {
            return;
        }
        onApply(selectedValue)
        setAnchorEl(null)
        setSelectOptions(options)
    }

    const handleListOptionClick = (optionValue: number) => () => {
        const newOptionValueSet = new Set([...selectedValue])
        if(newOptionValueSet.has(optionValue)) {
            newOptionValueSet.delete(optionValue)
        } else {
            newOptionValueSet.add(optionValue)
        }
        setSelectedValue([...newOptionValueSet])
    }

    let comboboxText = placeholder
    const selectedOptionValueset = new Set(selectedValue)
    if((value ?? []).length === 1) {
        comboboxText = selectOptions.find(option => option.value === value[0])!.label
    }

    if((value ?? []).length > 1) {
        comboboxText = `${value.length} selected`
    }

    return (
        <div className={classes.root} style={{ width: selectBoxWidth }} >
            <div className={classes.selectControl} >
                {label && <label>{label}</label>}
                { error && showError  && handleCloseError && <GenericError handleCloseError={handleCloseError} error={error} /> }
                <button 
                    role="combobox" 
                    aria-disabled={disabled || options.length === 0} 
                    onClick={handleOpenPopover} 
                    className={classes.combobox} 
                >
                    {comboboxText}
                    <ExpandMoreRounded htmlColor="#64748B" />
                </button>
            </div>
            <Popover
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClosePopover}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                id="popover-multi-select-checkbox"
                PaperProps={{ style: { width: selectBoxWidth, ...paperPropsStyle } }}
            >
                <div>
                    {
                        hasSearch && (
                            <div className={classes.searchBoxContainer} >
                        <div className={classes.searchBoxInnerContainer} >
                            <SearchRounded htmlColor="#94A3B8" />
                            <input
                                type="text"
                                className={classes.searchInput}
                                placeholder={searchPlaceholder}
                                aria-label="search"
                                onChange={handleSearchInputChange}
                            />
                        </div>
                    </div>
                        )
                    }
                    <ul className={classes.listbox} >
                        {
                            selectOptions.length > 0 ? (
                                selectOptions.map(option => (
                                    <li
                                        key={option.value}
                                        role="option" 
                                        aria-selected={selectedOptionValueset.has(option.value)} 
                                        className={classes.listOption}
                                        onClick={handleListOptionClick(option.value)} 
                                    >
                                        {selectedOptionValueset.has(option.value) ? <CheckedIcon /> : <UncheckedIcon />}
                                        {option.label}
                                    </li>
                                ))
                            ) : null
                        }
                    </ul>
                    <div className={classes.actionContainer} >
                        <button className="cancel" onClick={handleClosePopover} >Cancel</button>
                        <button className="apply" onClick={handleApplyOptionsChange} >Apply</button>
                    </div>
                </div>
            </Popover>
        </div>
    )
}


function UncheckedIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="19" height="19" rx="5.5" stroke="#64748B" />
        </svg>
    )
}

function CheckedIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="6" fill="#51ABB3" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.0611 5.42238C15.5183 5.73229 15.6376 6.35411 15.3277 6.81124L10.2432 14.3112C10.0771 14.5562 9.8111 14.715 9.51671 14.745C9.22232 14.7749 8.92977 14.673 8.71777 14.4665L4.80234 10.6536C4.40667 10.2683 4.39827 9.6352 4.78358 9.23953C5.16888 8.84386 5.80199 8.83546 6.19766 9.22077L9.25771 12.2007L13.6723 5.68895C13.9822 5.23182 14.604 5.11247 15.0611 5.42238Z" fill="white" />
        </svg>
    )
}
