import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export interface ChatsProps {
    id: string;
    navigation: any;
}

interface S {
    isOpenChatTabActive: boolean;
    exportAnchorEl: HTMLButtonElement |null;
}

interface SS {}

export default class ChatsController extends BlockComponent<ChatsProps, S, SS> {
    chatUsers = [
        {
            name: "#0001",
            botName: "Chatbot name",
            time: "12:45 PM"
        },
        {
            name: "#0002",
            botName: "Chatbot name",
            time: "12:45 PM"
        },
        {
            name: "#0003",
            botName: "Chatbot name",
            time: "12:45 PM"
        },
        {
            name: "#0004",
            botName: "Chatbot name",
            time: "12:45 PM"
        },
        {
            name: "#0005",
            botName: "Chatbot name",
            time: "12:45 PM"
        },
        {
            name: "#0006",
            botName: "Chatbot name",
            time: "12:45 PM"
        },
        {
            name: "#0007",
            botName: "Chatbot name",
            time: "12:45 PM"
        },
        {
            name: "#0008",
            botName: "Chatbot name",
            time: "12:45 PM"
        },
        {
            name: "#0009",
            botName: "Chatbot name",
            time: "12:45 PM"
        },
        {
            name: "#0010",
            botName: "Chatbot name",
            time: "12:45 PM"
        },
        {
            name: "#0011",
            botName: "Chatbot name",
            time: "12:45 PM"
        },
        {
            name: "#0012",
            botName: "Chatbot name",
            time: "12:45 PM"
        },
        {
            name: "#0013",
            botName: "Chatbot name",
            time: "12:45 PM"
        },
    ]
    constructor(props: ChatsProps) {
        super(props)
        this.receive = this.receive.bind(this)

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            isOpenChatTabActive: true,
            exportAnchorEl: null,
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    onToggleChatTab = (isActive: boolean) => () => {
        this.setState({isOpenChatTabActive: isActive})
    }

    onShowExportPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({exportAnchorEl: event.currentTarget})
    }

    onCloseExportPopover = () => {
        this.setState({exportAnchorEl: null})
    }
}