import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Radio,
  Switch,
  styled,
  Checkbox
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import UncheckedIcon from "./components/UncheckedIcon.web";
import CheckedIcon from "./components/CheckedIcon.web";
import CustomSelect from "../../../components/src/CustomSelect.web";
import RowComponent from "./components/Row.web";
import Column from "./components/Column.web";
import ChatbotInput from "./components/ChatbotInput.web";
import UploadButton from "./components/UploadButton.web";
import ImageFileInput from "../../../components/src/ImageFileInput.web";
import { ClearRounded } from "@material-ui/icons"

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    h3: {
      fontFamily: "Cairo",
      fontWeight: 700,
      fontSize: 16,
      lineHeight: "24px",
      color: "#0F172A",
    },
    subtitle1: {
      fontFamily: "Cairo",
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "24px",
      color: "#64748B",
      '& .font-400': {
        fontWeight: 400,
      },
    },
  },
  overrides: {
    MuiFormLabel : {
      root: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "24px",
        fontFamily: "Cairo",
        color: "#0F172A",
        '&.Mui-focused': {
          color: "#0F172A"
        },
        '&.font-700': {
          fontWeight: 700,
        },
      },
    },
    MuiSwitch: {
      input: {
        left: 0,
        height: 28,
        width: 48,
      },
      root: {
        padding: 0,
        borderRadius: 40,
        height: 28,
        width: 48,
      },
      thumb: {
        width: 24,
        boxShadow: "0px 2px 8px 0px #00000014",
        height: 24,
        backgroundColor: "#FFFFFF",
      },
      switchBase: {
        left: 2,
        padding: 0,
        top: 2,
        '&.Mui-checked': {
            '& input': {
                left: "-24px"
            },
        },
      },
      colorSecondary: {
        '&.Mui-checked + $track': {
          opacity: 1,
          backgroundColor: "#51ABB3",
        },
      },
      track: {
        opacity: 1,
        backgroundColor: "#E2E8F0",
      },
    },
  },
});

const RemoveQRCodeImage = styled("button")({
  all: "unset",
  cursor: "pointer",
  position: "absolute",
  zIndex: 2,
  top: -10,
  right: -10,
  display: "grid",
  placeItems: "center",
  height: 24,
  width: 24,
  backgroundColor: "#51ABB3",
  borderRadius: 50,
})
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Column>
          <Typography variant="h3" >When should the bot initiate chat?*</Typography>
          <Typography variant="subtitle1">On mobile</Typography>
          <RowComponent>
            <Radio 
              checked={this.props.chatInitiateOnMobile === "when_visitors_land_on_the_site"}
              data-checked={this.props.chatInitiateOnMobile === "when_visitors_land_on_the_site"}
              onChange={this.handleChatbotInitiateOnMobileChange}
              value="when_visitors_land_on_the_site"
              name="chat-initiate-on-mobile"
              icon={<UncheckedIcon />}
              checkedIcon={<CheckedIcon />}
              disableRipple
              style={{ padding: 0 }}
              data-testid="when_visitors_land_on_the_site_on_mobile"
              inputProps={{"aria-label": "When visitors land on the site on mobile"}}
            />
            <InputLabel>When visitors land on the site</InputLabel>
          </RowComponent>
          <RowComponent>
            <Radio 
              checked={this.props.chatInitiateOnMobile === "when_visitor_click_the_chat_widget"}
              data-checked={this.props.chatInitiateOnMobile === "when_visitor_click_the_chat_widget"}
              onChange={this.handleChatbotInitiateOnMobileChange}
              value="when_visitor_click_the_chat_widget"
              name="chat-initiate-on-mobile"
              icon={<UncheckedIcon />}
              checkedIcon={<CheckedIcon />}
              disableRipple
              style={{ padding: 0 }}
              data-testid="when_visitors_click_the_chat_widget_on_mobile"
              inputProps={{"aria-label": "When visitors click the chta widget on mobile"}}
            />
            <InputLabel >When visitors click the chat widget</InputLabel>
          </RowComponent>
          <Typography variant="subtitle1">On desktop</Typography>
          <RowComponent>
            <Radio 
              checked={this.props.chatInitiateOnDesktop === "when_visitors_land_on_the_site"}
              data-checked={this.props.chatInitiateOnDesktop === "when_visitors_land_on_the_site"}
              onChange={this.handleChatbotInitiateOnDesktopChange}
              value="when_visitors_land_on_the_site"
              name="chat-initiate-on-desktop"
              icon={<UncheckedIcon />}
              checkedIcon={<CheckedIcon />}
              disableRipple
              style={{ padding: 0 }}
              data-testid="when_visitors_land_on_the_site_on_desktop"
              inputProps={{"aria-label": "When visitors land on the site on desktop"}}
            />
            <InputLabel >When visitors land on the site</InputLabel>
          </RowComponent>
          <RowComponent>
            <Radio 
              checked={this.props.chatInitiateOnDesktop === "when_visitor_click_the_chat_widget"}
              data-checked={this.props.chatInitiateOnDesktop === "when_visitor_click_the_chat_widget"}
              onChange={this.handleChatbotInitiateOnDesktopChange}
              value="when_visitor_click_the_chat_widget"
              name="chat-initiate-on-desktop"
              icon={<UncheckedIcon />}
              checkedIcon={<CheckedIcon />}
              disableRipple
              style={{ padding: 0 }}
              data-testid="when_visitors_click_the_chat_widget_in_dektop"
              inputProps={{"aria-label": "When visitors click the chta widget on desktop"}}
            />
            <InputLabel >When visitors click the chat widget</InputLabel>
          </RowComponent>
        </Column>
        <Column>
          <Typography id="response" variant="h3" >Response interval*</Typography>
          <Typography variant="subtitle1" className="font-400" >
            Add an interval to your bots response time during interactive chats for a more natural conversational flow.
          </Typography>
          <CustomSelect 
            options={this.responseIntervalOptions}
            selectBoxWidth={401}
            value={this.props.responseInterval}
            labelId="response"
            id="select-response-interval"
            onChange={this.handleResponseIntervalChange}
            placeholder="Select an interval"
          />
        </Column>
        <Column className="gap-16" >
          <Typography variant="h3" >What user actions should the bot allow?*</Typography>
          <Typography variant="subtitle1" className="font-400" >
            Allow visitors to go back to the previous message in the bot conversation or restart the conversation if required
          </Typography>
          <RowComponent>
            <Checkbox 
              checked={this.props.userAction.includes("go_back_to_previous_message")}
              data-checked={this.props.userAction.includes("go_back_to_previous_message")}
              onChange={this.handleUserActionChange}
              value="go_back_to_previous_message"
              name="user-action-previous"
              icon={<UncheckedIcon />}
              checkedIcon={<CheckedIcon />}
              disableRipple
              style={{ padding: 0 }}
              data-testid="previous_message"
              id="previous_message"
            />
            <InputLabel htmlFor="previous_message" >Go back to the previous message</InputLabel>
          </RowComponent>
          <RowComponent>
            <Checkbox
              checked={this.props.userAction.includes("restart_conversation")}
              data-checked={this.props.userAction.includes("restart_conversation")}
              onChange={this.handleUserActionChange}
              value="restart_conversation"
              name="user-action-restart"
              icon={<UncheckedIcon />}
              checkedIcon={<CheckedIcon />}
              disableRipple
              style={{ padding: 0 }}
              data-testid="restart_conversation"
              id="restart_conversation"
            />
            <InputLabel htmlFor="restart_conversation" >Restart conversation</InputLabel>
          </RowComponent>
        </Column>
        <Column>
          <InputLabel className="font-700" htmlFor="chatbot-url">Chatbot URL*</InputLabel>
          <ChatbotInput value={this.props.chatbotURL} onChange={this.handleChatbotURLChange} />
        </Column>
        <Column>
          <Typography variant="h3" >QR code upload*</Typography>
          {
            this.props.qrCodeImageURL ? (
              <div style={{position: "relative", width: 95}} >
                  <img src={this.props.qrCodeImageURL} alt="qr code" width="95" height="95" />
                  <RemoveQRCodeImage aria-label="remove qr code image" onClick={this.handleRemoveQRCodeImage} >
                    <ClearRounded htmlColor="#FFFFFF" style={{height: 20, width: 20}} />
                  </RemoveQRCodeImage>
              </div>
            ) : (
                <>
                  <UploadButton onClick={this.handleUploadButtonClick} />
                  <ImageFileInput 
                    name="qr-input"
                    ref={this.imageInputRef}
                    onImageLoad={this.onImageLoad}
                  />
                </>
            )
          }
          
        </Column>
        <Column>
          <Typography variant="h3" >EDATT+</Typography>
          <RowComponent className="gap-16" >
            <InputLabel htmlFor="openai" style={{maxWidth: 549}}>
              The chatbot will use Open AI, trained by resources included in the training hub, to provide visitors with accurate answers.
            </InputLabel>
            <Switch 
              disableRipple
              id="openai"
              name="openai"
              checked={this.props.shouldUseOpenAI}
              inputProps={{"aria-checked": this.props.shouldUseOpenAI}}
              onChange={this.handleOpenAiChange}
            />
          </RowComponent>
        </Column>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
