import React from "react";
import { Formik } from "formik";
import { InputLabel, OutlinedInput, Switch, ThemeProvider, Typography, createTheme, makeStyles } from "@material-ui/core";
import CustomSelect from "../../../../components/src/CustomSelect.web";
import * as yup from "yup";
import { 
    theme as cardTheme, 
} from "../theme.web";
import CardHeader from "./CardHeader.web";
import CardFooter from "./CardFooter.web";
import MessageTextArea from "./MessageTextArea.web";

const {
    MuiFormLabelRootStyle,
    MuiTypographyStyle, 
    MuiOutlinedInputStyle,
    MuiInputBaseRootStyle,
    drawerCard,
} = cardTheme

export type EndChatProps = {
    onClose: () => void;
    onSubmit: (values: EndChatForm) => void;
    loading: boolean;
    title?: string;
    message?: string;
    waitTime?: number;
    id?: number;
    onDrawerClose: () => void;
    botContext: string[];
}

export type EndChatForm = {
    name: string;
    waitTime: number;
    message: string;
    cardWaitTimeChecked: boolean;
    id?: number;
    type: "send_message" | "end_chat";
}

const theme = createTheme({
    typography: {
        ...MuiTypographyStyle,
    },
    overrides: {
        MuiFormLabel: {
            root: {
                ...MuiFormLabelRootStyle,
            }
        },
        MuiInputBase: MuiInputBaseRootStyle,
        MuiOutlinedInput: MuiOutlinedInputStyle,
        MuiSwitch: {
            root: {
                borderRadius: 40,
                padding: 0,
                width: 48,
                height: 28,
            },
            input: {
                width: 48,
                left: 0,
                height: 28,
            },
            switchBase: {
                left: 2,
                top: 2,
                padding: 0,
                '&.Mui-checked': {
                    '& input': {
                        left: "-24px",
                    },
                },
            },
            thumb: {
                backgroundColor: "#FFFFFF",
                height: 24,
                boxShadow: "0px 2px 8px 0px #00000014",
                width: 24,
            },
            track: {
                backgroundColor: "#E2E8F0",
                opacity: 1,
            },
            colorSecondary: {
                '&.Mui-checked + $track': {
                    opacity: 1,
                    backgroundColor: "#51ABB3",
                },
            },
        },
        MuiIconButton: {
            root: {
                padding: 12,
            },
        },
    }
})

const useStyles = makeStyles({
    drawerContentType: drawerCard.drawerContent,
    formControls: drawerCard.formControl,
    endMessageContainer: drawerCard.container,
    formErrorInfo: {
        display: "flex",
        gap: 4,
        justifyContent: "flex-end",
        '&.spaceBetween': {
            justifyContent: "space-between",
        },
        '&.error': {
            color: "#D97706",
        },
    },
    switchContainers: {
        display: "flex",
        marginBottom: 8,
        alignItems: "flex-end",
        justifyContent: "space-between",
    },
})

export const EndChatValidationSchema = yup.object().shape({
    name: yup.string().trim().required("Please add a name"),
    message: yup.string().trim().required("Please add a message"),
})


const waitTimeOptions: Array<Option> = [
    {
        label: "5 seconds",
        value: "5"
    },
    {
        label: "6 seconds",
        value: "6"
    },
    {
        label: "7 seconds",
        value: "7",
    },
    {
        label: "8 seconds",
        value: "8"
    },
    {
        label: "9 seconds",
        value: "9"
    },
    {
        label: "10 seconds",
        value: "10",
    }
]

type Option = {
    label: string;
    value: string;
}



export default function EndChat ({ 
    onClose, 
    onSubmit, 
    loading,
    title="",
    message="",
    waitTime=5,
    id,
    onDrawerClose, 
    botContext
}: EndChatProps) {
    const classes = useStyles()
    return (
        <ThemeProvider theme={theme} >
            <div className={classes.endMessageContainer}>
                <CardHeader id={id} onClose={onClose} title="End Chat" data-testid="cardHeader" />
                <Formik
                    initialValues={{
                        name: title,
                        message: message,
                        waitTime: waitTime,
                        cardWaitTimeChecked: false,
                    }}
                    validationSchema={EndChatValidationSchema}
                    onSubmit={(values) => onSubmit({id,type: "end_chat", ...values})}
                    data-test-id="formik"
                >
                    {({ handleSubmit, values, errors, getFieldProps, setFieldValue, touched }) => (
                        <>
                            <div className={classes.drawerContentType}>
                                <div className={classes.formControls} >
                                    <InputLabel htmlFor="title" >Name*</InputLabel>
                                    <OutlinedInput
                                        placeholder="End chat card"
                                        fullWidth
                                        id="name"
                                        name="name"
                                        inputProps={{ ...getFieldProps("name"), maxLength: 50 }}
                                        error={!!errors.name && touched.name}
                                        data-test-id="name"
                                    />
                                    <div className={`${classes.formErrorInfo} ${(errors.name && touched.name) ? "spaceBetween" : ""}`}>
                                        {
                                            errors.name && touched.name && <Typography data-test-id="nameError" id="name-error" className="error" variant="subtitle2" >{errors.name}</Typography>
                                        }
                                        <Typography variant="subtitle2" id="name-value-length" >{values.name.trimStart().trimEnd().length}/50</Typography>
                                    </div>
                                </div>
                                <MessageTextArea 
                                    value={values.message}
                                    hasMessageError={!!errors.message && !!touched.message}
                                    messageErrorText={errors.message}
                                    getFieldProps={getFieldProps}
                                    botContext={botContext}
                                    setFieldValue={setFieldValue}
                                />
                                <div className={classes.formControls} style={{ gap: 0 }} >
                                    <InputLabel id="waitTime" htmlFor="title" >Card wait time</InputLabel>
                                    <div className={classes.switchContainers}>
                                        <Typography variant="subtitle2" >
                                            The card will be executed after the specified wait time
                                        </Typography>
                                        <Switch
                                            checked={values.cardWaitTimeChecked}
                                            disableRipple
                                            id="cardWaitTimeChecked"
                                            inputProps={{ ...getFieldProps("cardWaitTimeChecked") }}
                                        />
                                    </div>
                                    <CustomSelect
                                        labelId="waitTime"
                                        placeholder="Wait Time"
                                        id="waitTime-select"
                                        options={waitTimeOptions}
                                        value={`${values.waitTime}`}
                                        onChange={(val: string) => setFieldValue("waitTime", +val)}
                                        disabled={!values.cardWaitTimeChecked}
                                        selectBoxWidth={344}
                                    />
                                </div>
                            </div>
                            <CardFooter onSaveClick={() => handleSubmit()} onClose={onDrawerClose} loading={loading} />
                        </>
                    )}
                </Formik>
            </div>
        </ThemeProvider>
    )
}
