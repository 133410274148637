import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { nodeHelpers } from "../../chatbot5/src/nodeHelpers.web";
import { utils } from "../../chatbot5/src/utils.web";
import { ConnectorCard } from "../../chatbot5/src/Chatbot5Controller";
import { NodeData } from "../../../components/src/CardNode.web"
import { Node } from "reactflow";

const { getNodeDataFromConnectorCardAttributes }= utils
const {getNewNodesWithNewEdges } = nodeHelpers

export type Chatbot = {
  id: number;
  name: string;
  organisation: string;
  code: string;
  link: string;
  qrcode: string;
  active: boolean;
  image: string | null;
}

export type GeneralSettings = {
  data: {
    id: string;
    type: "setting";
    attributes: {
      practice_association: string[];
      when_should_bot_initiate_chat: {
        mobile: string[];
        desktop: string[];
      };
      response_interval: null | number;
      what_user_action_should_the_bot_allow: {
        device: string[];
      };
      chatbot_url: null | string;
      chatbot_id: number;
      edatt: boolean;
      qr_code: null | string;
    };
  };
};

export type DesignSettings= {
  data: {
    id: string;
    type: "customise_interface";
    attributes: {
      id: number;
      color: null | string;
      header_color: string;
      label: string;
      text_size: null | string;
      background_color: string;
      contrast: null | string;
      catalogue_id: null | string;
      widget_type: string;
      practice_association: string[];
      chatbot_name: string;
      widget_url: null | string;
    };
  };
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  tabValue: "grid" | "list";
  chatbots: Chatbot[];
  currentPage: number;
  showCreateBotDialog: boolean;
  token: string;
  search: any;
  chatBotId: number | null;
  pagination: {
    total: number;
    itemPerPage: number;
    currentPage: number;
  };
  generalSettingsMap: Map<number, GeneralSettings>;
  designSettingsMap: Map<number, DesignSettings>;
  connectorCardsMap: Map<number, NodeData[]>;
  hasChatbotCardsError: boolean;
  hasChatbotSettingsError: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Catalogue1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllChatBotId: string|null= "";
  deleteChatBotId: string|null= "";
  duplicateChatBotId: string|null= "";
  updateChatBotId: string|null= "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      isLoading: false,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      tabValue: "grid",
      chatbots: [],
      currentPage: 1,
      showCreateBotDialog: false,
      token: "",
      search: "",
      chatBotId: null,
      pagination: {
        total: 0,
        itemPerPage: 16,
        currentPage: 1
      },
      generalSettingsMap: new Map(),
      designSettingsMap: new Map(),
      connectorCardsMap: new Map(),
      hasChatbotCardsError: false,
      hasChatbotSettingsError: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    this.handleResponseForGetAllChatbotsData(message)
    this.handleResponseForDeleteChatbot(message)
    this.handleResponseForDuplicateChatbot(message)
    this.handleResponseForUpdateChatbotStatus(message)
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  onTabChange = (newValue: "grid" | "list") => {
    this.setState({tabValue: newValue})
  }

  onPageChange = (event: any, newPage: number) => {
    this.setState((prev) => ({pagination: {...prev.pagination, currentPage: newPage}}), () => this.getAllChatbots())
  }

  onToggleCreateBotDialog = () => {
    this.setState({showCreateBotDialog: !this.state.showCreateBotDialog})
  }

  handleDeleteChatBotId = (id: number)=>{
    this.setState({chatBotId: id, isLoading: true}, () => this.deleteChatbot(id))
  }

  handleDuplicateBotId = (id: number)=>{
    this.setState({chatBotId: id, isLoading: true}, () => this.duplicateChatbot(id))
  }

  handleCheckErrorInConnectorCards = (id: number) => {
    const botNodeData = this.state.connectorCardsMap.get(id) ?? []
    const initialHomeNode: Node<NodeData> = {
      id: 'start', 
      type: "homeNode", 
      position: { x: 24, y: 24 },
      data: {
          title: "Home",
          message: "Home",
          status: "disconnected",
          source: [],
          type: "send_message",
          id: Date.now(),
          connectorCardType: "chatbot"
      },
    }
    let nodeCardData: Node<NodeData>[] = [initialHomeNode]
    botNodeData.forEach((nodeData) => {
      nodeCardData.push({
        id: `${nodeData.id}`,
        position: {x: 0, y: 0},
        type: "cardNode",
        data: {
            ...nodeData,
            onClick: () => {},
            onCardNodeDelete: () => {}
        },
      })
    })
    const {newNodes} = nodeHelpers.getNewNodesWithNewEdges({
      nodes: nodeCardData,
      onAddNodeClick: () => {},
      checkForError: true
    })

    const hasError = newNodes.some(node => !!node.data.error) || botNodeData.length === 0
    return hasError
  }

  handleCloseSettingsError = () => {
    this.setState({hasChatbotSettingsError: false, chatBotId: null})
  }

  handleCloseBotConnectorCardsError = () => {
    this.setState({hasChatbotCardsError: false, chatBotId: null})
  }

  handleEditChatbot = () => {
    if(this.state.chatBotId !== null) {
      this.navigateToEditPage(this.state.chatBotId)
    }
  }

  handleNavigateToBotSettingsPage = () => {
    const message = new Message(getName(MessageEnum.NavigationSettingsMessage))

    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )

    message.addData(
      getName(MessageEnum.NavigationScreenNameMessage),
      this.state.chatBotId
    )
    
    this.send(message)
  }

  handleBotStatusChange = (id: number) => {
    const currentChatbot = this.state.chatbots.find(chatbot => chatbot.id === id)!
    if(!currentChatbot.active) {
      const chatbotErrors = this.handleCheckErrorInConnectorCards(id)
      if(chatbotErrors) {
        this.setState({hasChatbotCardsError: true, chatBotId: id})
        return;
      }
  
      const chatbotGeneralSettings = this.state.generalSettingsMap.get(id)!
      const { chatbot_url, qr_code } = chatbotGeneralSettings.data.attributes
      if(!chatbot_url || !qr_code) {
        this.setState({hasChatbotSettingsError: true, chatBotId: id})
        return;
      }
    }
    
    const chatbots = this.state.chatbots.map(bot => {
      if(+bot.id === id) return {...bot, active: !bot.active}
      return bot
    })
    
    this.setState({chatbots, chatBotId: id}, () => this.updateChatbotStatus(id, currentChatbot.active))
  }

  handleSearchChange = (event: any) => {
    sessionStorage.removeItem("search")
    this.setState({search: event.target.value}, () => this.getAllChatbots())
  }

  navigateToEditPage = (id: number) => {
    const navigationMessage = new Message(getName(MessageEnum.NavigationIndividualBotMessage))
    navigationMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), id)
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      { navigation: this.props.navigation }
    )
    this.send(navigationMessage)
  }
  async componentDidMount() {
    const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
    const search = sessionStorage.getItem("search") || ""
    this.setState({ token, isLoading: true, search })
    this.getAllChatbots();
    sessionStorage.removeItem("search")
  }

  getAllChatbots = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllChatBotId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createBotApiEndpoint}?search=${this.state.search}&per_page=${this.state.pagination.itemPerPage}&page=${this.state.pagination.currentPage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deleteChatbot = async (id: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteChatBotId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createBotApiEndpoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.delete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  duplicateChatbot = async (id: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.duplicateChatBotId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createBotApiEndpoint}/${id}/create_duplicate_bot`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.post
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateChatbotStatus = async (id: number, active: boolean) => {
    const status = active ? "inactive" : "active"
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateChatBotId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createBotApiEndpoint}/${id}/update_status`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({status})
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.post
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleResponseForGetAllChatbotsData = async (message: Message) => {
    if (
      this.getAllChatBotId !== null &&
      this.getAllChatBotId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.chatbots) {
        const generalSettingsMap = new Map<number, GeneralSettings>()
        const designSettingsMap = new Map<number,DesignSettings>()
        const connectorCardsMap = new Map<number, NodeData[]>()
        const chatbots = responseJson.chatbots.map((bot: any) => {
          const data = bot.data
          generalSettingsMap.set(data.attributes.id, bot.general_setting)
          designSettingsMap.set(data.attributes.id, bot.design_setting)
          const nodeData = data.attributes.connector_cards.map((card: {data: ConnectorCard}) => getNodeDataFromConnectorCardAttributes(card.data)) as NodeData[]
          connectorCardsMap.set(data.attributes.id, nodeData)
          return {
            id: data.attributes.id,
            organisation: data.attributes.practice_association_name,
            name: data.attributes.name.trim(),
            code: data.attributes.general_link,
            link: data.attributes.iframe_link,
            qrcode: bot.general_setting.data.attributes.qr_code,
            active: data.attributes.status === "active" ? true : false,
            image: bot.design_setting.data.attributes.widget_url
          }
        }) as Chatbot[]
        const {total_items: total, per_page: itemPerPage, current_page: currentPage} = responseJson.meta
        this.setState({
          chatbots: chatbots, 
          isLoading: false, 
          pagination: {total, itemPerPage, currentPage},
          generalSettingsMap,
          designSettingsMap,
          connectorCardsMap
        })
      } else if (responseJson?.errors?.length > 0 && responseJson?.errors?.[0]?.token) {
        await this.handleLogout()
      }
    }
  }
  handleResponseForDeleteChatbot = async (message: Message) => {
    if (
      this.deleteChatBotId !== null &&
      this.deleteChatBotId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({isLoading: false, chatBotId: null})
      if (responseJson?.message) {
        let page = this.state.pagination.currentPage
        if (this.state.chatbots.length === 1 && this.state.pagination.currentPage > 1) {
          page = this.state.pagination.currentPage - 1
        }
        this.setState((prev) => ({
          pagination: {
            ...prev.pagination,
            currentPage: page
          }
        }))
        toast.success("The chatbot was successfully deleted")
        this.getAllChatbots()
      } else if (responseJson?.errors?.length > 0 && responseJson?.errors?.[0]?.token) {
        await this.handleLogout()
      } else if(responseJson?.error){
        toast.error(responseJson.error || "Please try again")
      }
    }
  }
  handleResponseForDuplicateChatbot = async (message: Message) => {
    if (
      this.duplicateChatBotId !== null &&
      this.duplicateChatBotId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({isLoading: false, chatBotId: null})
      if (responseJson?.data) {
        toast.success("The duplicate chatbot was successfully created")
        this.getAllChatbots()
      } else if (responseJson?.errors?.length > 0 && responseJson?.errors?.[0]?.token) {
        await this.handleLogout()
      } else if(responseJson?.errors?.length){
        toast.error(responseJson.error[0] || "Please try again")
      }
    }
  }
  handleResponseForUpdateChatbotStatus = async (message: Message) => {
    if (
      this.updateChatBotId !== null &&
      this.updateChatBotId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.chatbot) {
        this.setState({chatBotId: null})
      } else if (responseJson?.errors?.length > 0 && responseJson?.errors?.[0]?.token) {
        await this.handleLogout()
      }
    }
  }
  async handleLogout() {
    await removeStorageData("authToken")
    await removeStorageData("userId")
    sessionStorage.clear()
    const message: Message = new Message(
      getName(MessageEnum.NavigationSignupLoginMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
}
