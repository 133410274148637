Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.putAPiMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AutomaticFormCreation";
exports.labelBodyText = "AutomaticFormCreation Body";
exports.getFeedDataEndPoint = "bx_block_custom_forms/custom_forms";
exports.putFormDataEndPoint = "bx_block_custom_forms/custom_forms"
exports.deleteFormDataEndPoint = "bx_block_custom_forms/custom_forms"

exports.btnExampleTitle = "Submit";

exports.putFormMethodType = "PUT";
exports.deleteFormMethodType = "DELETE";

exports.getPracticeData = "bx_block_catalogue/tags/practice";
exports.createForm = "bx_block_custom_forms/custom_forms";

// Customizable Area End