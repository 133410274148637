import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, { 
    Background, 
    BackgroundVariant, 
    MarkerType, 
    addEdge, 
    useEdgesState, 
    useNodesState, 
    Node,
    useReactFlow,
} from 'reactflow';
import HomeNode from "./HomeNode.web"
import AddNodeButton from "./AddNodeButton.web";
import SmoothDashEdge from "./SmoothDashEdge.web";
import FlowControl from "./FlowControl.web";
import CardNode, { NodeData } from "./CardNode.web";
import { nodeHelpers } from "../../blocks/chatbot5/src/nodeHelpers.web";
import NodeDataSelection from "./NodeDataSelection.web";
import { DeleteOutlineRounded, Edit } from "@material-ui/icons";
import CategoryTemplatePopup from "../../blocks/projecttemplates/src/components/CategoryTemplatePopup.web";


const nodeTypes = {
    homeNode: HomeNode,
    addNode: AddNodeButton,
    cardNode: CardNode,
}

const edgeTypes = {
    smoothDashEdge: SmoothDashEdge
}

type WhiteboardProps = {
  cardData: NodeData[];
  isTemplate: boolean;
  onAddNodeClick: (data: Omit<NodeData,"status" | "onClick">) => void;
  onCardNodeClick: (data: Omit<NodeData,"status" | "onClick">) => void;
  onCardNodeDelete: (node: {id: number, title: string, source: string[], criteriaRoutingId?: number}) => void;
  onTemplateCreate: (nodes: Node<NodeData>[], name: string) => void;
  checkForError: boolean;
  handleConnectNode: (targetNodeId: string, source: string[], criteriaRoutingId?: number, followUpActionId?: number) => void;
  templateCreateLoading: boolean;
  handleDeleteMultipleCards: (nodes: Node<NodeData>[]) => void;
}

const createHTMLElementFromNode = (node: NodeData) => {
    const div = document.createElement("div");
    div.id = `${node.id}`;
    div.style.width = "327px";

    const nameP = document.createElement("p");
    nameP.textContent = node.title;
    nameP.style.padding = "8px 16px";
    nameP.style.margin = "0px";
    nameP.style.lineHeight = "24px";
    nameP.style.boxSizing = "border-box";

    const messageP = document.createElement("p");
    messageP.textContent = node.message;
    messageP.style.padding = "16px";
    messageP.style.margin = "0px";
    messageP.style.lineHeight = "24px";
    messageP.style.boxSizing = "border-box";
    messageP.className = "white-space-collapse";
    
    if(node.type === "criteria_routing" || node.type === "go_to_tile" || node.type === "openai") {
        messageP.style.height = "56px"
    }

    div.appendChild(nameP);
    div.appendChild(messageP);

    return div
}

export default function Whiteboard({
    cardData, 
    onAddNodeClick, 
    onCardNodeClick,
    onCardNodeDelete,
    onTemplateCreate,
    isTemplate,
    checkForError,
    handleConnectNode,
    templateCreateLoading,
    handleDeleteMultipleCards,
}: WhiteboardProps) {
    const initialHomeNode: Node<NodeData> = {
        id: 'start', 
        type: "homeNode", 
        position: { x: 24, y: 24 },
        data: {
            title: "Home",
            message: "Home",
            status: "disconnected",
            source: [],
            type: "send_message",
            id: Date.now(),
            connectorCardType: isTemplate ? "template" : "chatbot",
            height: 44,
        },
    }
    
    const initialAddNodeData: NodeData = {
        title: "Add Node",
        message: "Add Node",
        status: "connected",
        type: "add_node",
        source: ["start"],
        id: Date.now(),
        connectorCardType: isTemplate ? "template" : "chatbot",
        height: 44,
    }
    const [nodes,setNodes, onNodesChange] = useNodesState<NodeData>([
        initialHomeNode,
        { id: 'end', type: "addNode", position: { x: 157, y: 138 }, data: { ...initialAddNodeData, onClick: onAddNodeClick } }
    ]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([
        { 
          id: 'e1-2', 
          source: 'start', 
          target: 'end',
          type: "smoothDashEdge",
          markerEnd: { type: MarkerType.ArrowClosed, color: "#51ABB3" },
          data: { strokeStyle: "dashed" } 
        },
    ]);
    const [showTemplatePopup, setShowTemplatePopup] = useState<boolean>(false)
    const selectedNodesRef = useRef<Node<NodeData>[]>([])

    const {getIntersectingNodes} = useReactFlow()


    useEffect(() => {

        if(cardData.length === 0) {
            const newNodes = [
                initialHomeNode,
                { id: 'end',position: { x: 157, y: 138 }, type: "addNode", data: { ...initialAddNodeData, onClick: onAddNodeClick } }
            ]
            const newEdges = [{
                id: "e1-2",
                type: "smoothDashEdge",
                markerEnd: { type: MarkerType.ArrowClosed, color: "#51ABB3" },
                target: "end",
                source: "start",
                data: { strokeStyle: "dashed" } 
            }]
            setNodes(newNodes)
            setEdges(newEdges)
            return;
        }

        const sortedCardData = cardData.sort((a,b) => a.id - b.id)
        const nodeCardData: Node<NodeData>[] = [initialHomeNode]

        const hiddenContainer = document.createElement("div");
        hiddenContainer.id = "hidden-container"
        hiddenContainer.style.position = "absolute";
        hiddenContainer.style.top = "-99999px";
        document.body.appendChild(hiddenContainer);

        const fragment = document.createDocumentFragment()

        sortedCardData.forEach((nodeData) => {
            nodeCardData.push({
                id: `${nodeData.id}`,
                position: {x: 0, y: 0},
                type: "cardNode",
                className: nodeData.className,
                data: {
                    ...nodeData,
                    onClick: onCardNodeClick,
                    onCardNodeDelete: onCardNodeDelete
                },
            })
            fragment.appendChild(createHTMLElementFromNode(nodeData))
        })

        hiddenContainer.appendChild(fragment)

        const {newEdges, newNodes} = nodeHelpers.getNewNodesWithNewEdges({
            nodes: nodeCardData, 
            onAddNodeClick,
            checkForError: checkForError
        })
        
        setNodes(newNodes)
        setEdges(newEdges)

        document.body.removeChild(hiddenContainer)
    }, [cardData, checkForError])
    
    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge(params, eds)),
        [setEdges],
    );

    const handleOpenTemplatePopup = (selectedNodes: Node<NodeData>[]) => {
        selectedNodesRef.current = selectedNodes
        setShowTemplatePopup(true)
    }

    const handleCloseTemplatePopup = () => setShowTemplatePopup(false)

    const onSubmit = (name: string) => {
        onTemplateCreate(selectedNodesRef.current, name)
    }

    const onNodeDragStop = useCallback((event: React.MouseEvent, node: Node<NodeData>) => {
        if(!node.id.startsWith("end") || node.data.source.includes("start")) {
            return;
        }
        const intersectingNode = getIntersectingNodes(node)[0]
        if(!intersectingNode) {
            return;
        }
        const source = node.data.source[0]
        if(intersectingNode.id.startsWith("end") || intersectingNode.id === "start" || intersectingNode.id === source) {
            return;
        }
        handleConnectNode(intersectingNode.id, node.data.source, node.data.criteriaRoutingId, node.data.followUpActionId)
    }, [])


    return (
        <ReactFlow 
            nodes={nodes} 
            edges={edges} 
            proOptions={{hideAttribution: true}}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            data-testid="flow"
            noPanClassName="no-pan"
            selectionKeyCode={isTemplate ? null : "Shift"}
            onNodeDragStop={onNodeDragStop}
            maxZoom={4}
            minZoom={0.2}
        >
            <NodeDataSelection data-testid="node-selection" >
                {(selectedNodes, handleClosePopover) => (
                    <>
                        <button 
                            data-testid="create-template" 
                            onClick={() => {
                                handleOpenTemplatePopup(selectedNodes)
                                handleClosePopover()
                            }} 
                        >
                            <Edit htmlColor="#0F172A" />
                            Create as New Template
                        </button>
                        <button 
                            onClick={() => {
                                handleDeleteMultipleCards(selectedNodes)
                                handleClosePopover()
                            }} 
                        >
                            <DeleteOutlineRounded htmlColor="#0F172A" />
                            Delete cards
                        </button>
                    </>
                )}
            </NodeDataSelection>
            <FlowControl />
            <Background variant={BackgroundVariant.Dots} color="#475569" gap={12} size={1} />
            <CategoryTemplatePopup
                data-testid="template-popup" 
                type="template"
                open={showTemplatePopup}
                handleClose={handleCloseTemplatePopup}
                onSubmit={onSubmit}
                isCreateOrUpdateLoading={templateCreateLoading}
            />    
        </ReactFlow>
    )
}
