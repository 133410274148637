import { GeneralSettingsState } from "./Customiseinterface2Controller";

type GeneralSettingsErrorArgs = Pick<GeneralSettingsState , "chatbotURL" | "qrCodeImageURL" | "userAction"> & {selectedPracticeValue: string}
type DesignSettingsErrorArgs = {
    selectedPracticeValue: string;
    headerLabel: string;
}

const hexToRgb = (hex: string) => {
    hex = hex.replace('#', '');

    if (hex.length === 3) {
        hex = hex.replace(/(.)/g, '$1$1');
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return {
        r: r,
        g: g,
        b: b
    };
}

const componentToHex = (c: number) => {
    const hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

const rgbToHex = (r: number, g: number, b: number) => {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

const getGeneralSettingsError = (values: GeneralSettingsErrorArgs) => {
    let error: string | undefined = undefined;
    const url_regex = /^https?:\/\/(?:[\w-]+\.)+[a-z]{2,}(?:\/[a-zA-Z0-9-_.~%]*)*(?:\?[a-zA-Z0-9-_.~%=&,]*)?(?:#[\w-]*)?$/
    if(!values.selectedPracticeValue) {
        error = "Please select the practice association"
    } else if(values.userAction.length === 0) {
        error = "Please select at least one user action"
    } else if(!url_regex.test(values.chatbotURL)) {
        error = "Please add a valid chatbot URL"
    } else if(!values.qrCodeImageURL) {
        error = "Please upload QR code"
    }
    return error
}

const getDesignSettingsError = (values: DesignSettingsErrorArgs) => {
    let error: string | undefined = undefined;
    if(!values.selectedPracticeValue) {
        error = "Please select the practice association"
    } else if(values.headerLabel.trim().length === 0) {
        error = "Please add a chatbot label"
    }

    return error
}

export const utils = {
    hexToRgb,
    componentToHex,
    rgbToHex,
    getGeneralSettingsError,
    getDesignSettingsError
}
