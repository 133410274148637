import React from "react";
import { Bar } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart } from "chart.js";

Chart.register(ChartDataLabels)


const patientsData = [
    { id: 1, month: 'Jan', patients: 3400, questionType: "general-enquiries" },
    { id: 2, month: 'Feb', patients: 1500, questionType: "sick-or-fit-notes" },
    { id: 3, month: 'Mar', patients: 4300, questionType: "appointments" },
    { id: 4, month: 'Apr', patients: 2100, questionType: "test-results" },
    { id: 5, month: 'May', patients: 7500, questionType: "prescriptions" },
    { id: 6, month: 'Jun', patients: 3400, questionType: "general-enquiries" },
    { id: 7, month: 'Jul', patients: 6200, questionType: "manage-or-check-referrals" },
    { id: 8, month: 'Aug', patients: 2000, questionType: "appointments" },
    { id: 9, month: 'Sep', patients: 1800, questionType: "sick-or-fit-notes" },
    { id: 10, month: 'Oct', patients: 5400, questionType: "test-results" },
    { id: 11, month: 'Nov', patients: 7200, questionType: "prescriptions" },
    { id: 12, month: 'Dec', patients: 4400, questionType: "general-enquiries" },
    { id: 13, month: 'Jan', patients: 4600, questionType: "manage-or-check-referrals" },
    { id: 14, month: 'Feb', patients: 2900, questionType: "appointments" },
    { id: 15, month: 'Mar', patients: 6100, questionType: "sick-or-fit-notes" },
    { id: 16, month: 'Apr', patients: 3700, questionType: "test-results" },
    { id: 17, month: 'May', patients: 8400, questionType: "prescriptions" },
    { id: 18, month: 'Jun', patients: 2300, questionType: "general-enquiries" },
    { id: 19, month: 'Jul', patients: 5400, questionType: "manage-or-check-referrals" },
    { id: 20, month: 'Aug', patients: 4900, questionType: "appointments" },
    { id: 21, month: 'Sep', patients: 5600, questionType: "sick-or-fit-notes" },
    { id: 22, month: 'Oct', patients: 2300, questionType: "test-results" },
    { id: 23, month: 'Nov', patients: 7100, questionType: "prescriptions" },
    { id: 24, month: 'Dec', patients: 4600, questionType: "general-enquiries" },
    { id: 25, month: 'Jan', patients: 7800, questionType: "manage-or-check-referrals" },
    { id: 26, month: 'Feb', patients: 2400, questionType: "appointments" },
    { id: 27, month: 'Mar', patients: 6500, questionType: "sick-or-fit-notes" },
    { id: 28, month: 'Apr', patients: 3300, questionType: "test-results" },
    { id: 29, month: 'May', patients: 5100, questionType: "prescriptions" },
    { id: 30, month: 'Jun', patients: 4100, questionType: "general-enquiries" },
    { id: 31, month: 'Jul', patients: 5300, questionType: "manage-or-check-referrals" },
    { id: 32, month: 'Aug', patients: 1500, questionType: "appointments" },
    { id: 33, month: 'Sep', patients: 4400, questionType: "sick-or-fit-notes" },
    { id: 34, month: 'Oct', patients: 3400, questionType: "test-results" },
    { id: 35, month: 'Nov', patients: 5200, questionType: "prescriptions" },
    { id: 36, month: 'Dec', patients: 6100, questionType: "general-enquiries" },
    { id: 37, month: 'Jan', patients: 7200, questionType: "manage-or-check-referrals" },
    { id: 38, month: 'Feb', patients: 4300, questionType: "appointments" },
    { id: 39, month: 'Mar', patients: 3300, questionType: "sick-or-fit-notes" },
    { id: 40, month: 'Apr', patients: 5400, questionType: "test-results" },
    { id: 41, month: 'May', patients: 2100, questionType: "prescriptions" },
    { id: 42, month: 'Jun', patients: 7600, questionType: "general-enquiries" },
    { id: 43, month: 'Jul', patients: 4500, questionType: "manage-or-check-referrals" },
    { id: 44, month: 'Aug', patients: 2400, questionType: "appointments" },
    { id: 45, month: 'Sep', patients: 2900, questionType: "sick-or-fit-notes" },
    { id: 46, month: 'Oct', patients: 6300, questionType: "test-results" },
    { id: 47, month: 'Nov', patients: 4800, questionType: "prescriptions" },
    { id: 48, month: 'Dec', patients: 5200, questionType: "general-enquiries" },
    { id: 49, month: 'Jan', patients: 4300, questionType: "manage-or-check-referrals" },
    { id: 50, month: 'Feb', patients: 3700, questionType: "appointments" },
    { id: 51, month: 'Mar', patients: 5600, questionType: "sick-or-fit-notes" },
    { id: 52, month: 'Apr', patients: 3200, questionType: "test-results" },
    { id: 53, month: 'May', patients: 5900, questionType: "prescriptions" },
    { id: 54, month: 'Jun', patients: 4500, questionType: "general-enquiries" },
    { id: 55, month: 'Jul', patients: 6300, questionType: "manage-or-check-referrals" },
    { id: 56, month: 'Aug', patients: 3700, questionType: "appointments" },
    { id: 57, month: 'Sep', patients: 2400, questionType: "sick-or-fit-notes" },
    { id: 58, month: 'Oct', patients: 4300, questionType: "test-results" },
    { id: 59, month: 'Nov', patients: 6200, questionType: "prescriptions" },
    { id: 60, month: 'Dec', patients: 5100, questionType: "general-enquiries" },
    { id: 61, month: 'Jan', patients: 3200, questionType: "manage-or-check-referrals" },
    { id: 62, month: 'Feb', patients: 4100, questionType: "appointments" },
    { id: 63, month: 'Mar', patients: 4500, questionType: "sick-or-fit-notes" },
    { id: 64, month: 'Apr', patients: 3700, questionType: "test-results" },
    { id: 65, month: 'May', patients: 7200, questionType: "prescriptions" },
    { id: 66, month: 'Jun', patients: 4800, questionType: "general-enquiries" },
    { id: 67, month: 'Jul', patients: 5400, questionType: "manage-or-check-referrals" },
    { id: 68, month: 'Aug', patients: 1600, questionType: "appointments" },
    { id: 69, month: 'Sep', patients: 2800, questionType: "sick-or-fit-notes" },
    { id: 70, month: 'Oct', patients: 6100, questionType: "test-results" }
];

const getFilteredQuestionTypePatientsData = (questionType: string) => {
    return patientsData.filter(data => data.questionType === questionType).map(data => data.patients)
}

const chartSetting = {
    width: 500,
    borderRadius: "8px"
};

const chartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov'],
    datasets: [
        {
            label: "Appointments",
            data: getFilteredQuestionTypePatientsData("appointments"),
            backgroundColor: "#314A84",
            borderRadius: 4
        },
        {
            label: "General Enquiries",
            data: getFilteredQuestionTypePatientsData("general-enquiries"),
            backgroundColor: "#FF606B",
            borderRadius: 4
        },
        {
            label: "Manage/check referrals",
            data: getFilteredQuestionTypePatientsData("manage-or-check-referrals"),
            backgroundColor: "#F59E0B",
            borderRadius: 4
        },
        {
            label: "Prescriptions",
            data: getFilteredQuestionTypePatientsData("prescriptions"),
            backgroundColor: "#34D399",
            borderRadius: 4
        },
        {
            label: "Seick/Fit Notes",
            data: getFilteredQuestionTypePatientsData("sick-or-fit-notes"),
            backgroundColor: "#C1C7CD",
            borderRadius: 4
        },
        {
            label: "Test Results",
            data: getFilteredQuestionTypePatientsData("test-results"),
            backgroundColor: "#7D6AEB",
            borderRadius: 4
        },
    ]
}


export default function PatientStackBarChart() {

    return (
        <div style={{ flex: 1, overflowX: "hidden" }} >
            <Bar
                data={chartData}
                options={{
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: true,
                            backgroundColor: '#F1F5F9',
                            bodyColor: '#F1F5F9',
                            titleColor: "#0F172A",
                            borderColor: '#F1F5F9',
                            borderWidth: 1,
                            titleFont: { weight: 'bold' },
                            callbacks: {
                                labelTextColor: function (context) {
                                    return '#0F172A';
                                }
                            }
                        },
                        datalabels: {
                            display: function (context) {
                                return context.datasetIndex === context.chart.data.datasets.length - 1;
                            },
                            align: 'end',
                            anchor: 'end',
                            font: {
                                weight: 600,
                                family: "Cairo",
                                size: 12,
                                lineHeight: "16px", 
                            },
                            color: '#0F172A',
                            formatter: function (value, context) {
                                const total = context.chart.data.datasets
                                    .map(dataset => dataset.data[context.dataIndex])
                                    .reduce((a, b) => {
                                        const aNum = a as number
                                        const bNum = b as number
                                        return aNum + bNum
                                    }, 0) as number;
                                const formatter = new Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 1 });
                                return formatter.format(total);
                            },


                        },
                    },
                    scales: {
                        x: {
                            stacked: true,
                            grid: { display: false }
                        },
                        y: {
                            stacked: true,
                            grid: { borderDash: [5, 5], borderColor: "transparent", tickColor: "transparent" },
                            ticks: {
                                callback: function (value: string | number) {
                                    const formatter = new Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 1 });
                                    return formatter.format(+value);
                                }
                            }
                        }
                    },
                    responsive: true,
                }}
                {...chartSetting}
                className="stacked-canvas"
            />
        </div>
    )
}