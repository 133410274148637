import React from 'react';
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
    button: {
        all: "unset",
        cursor: "pointer",
        width: 400,
        padding: 10,
        border: "1px dashed #51ABB3",
        backgroundColor: "#51ABB31A",
        borderRadius: 8,
        display: "flex",
        justifyContent: "center",
    },
})


export default function UploadButton({onClick}: {onClick: () => void;}) {
    const classes = useStyles()

    return (
        <button className={classes.button} aria-label="Upload qr code" onClick={onClick} >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.18945 19.9531C4.67279 19.9531 3.37712 19.4281 2.30245 18.3781C1.22712 17.3281 0.689453 16.0448 0.689453 14.5281C0.689453 13.2281 1.08112 12.0698 1.86445 11.0531C2.64779 10.0365 3.67279 9.38646 4.93945 9.10312C5.35612 7.56979 6.18945 6.32813 7.43945 5.37813C8.68945 4.42813 10.1061 3.95312 11.6895 3.95312C13.6395 3.95312 15.2935 4.63213 16.6515 5.99013C18.0101 7.34879 18.6895 9.00313 18.6895 10.9531C19.8395 11.0865 20.7938 11.5821 21.5525 12.4401C22.3105 13.2988 22.6895 14.3031 22.6895 15.4531C22.6895 16.7031 22.2521 17.7658 21.3775 18.6411C20.5021 19.5158 19.4395 19.9531 18.1895 19.9531H12.6895C12.1395 19.9531 11.6688 19.7575 11.2775 19.3661C10.8855 18.9741 10.6895 18.5031 10.6895 17.9531V12.8031L9.08945 14.3531L7.68945 12.9531L11.6895 8.95312L15.6895 12.9531L14.2895 14.3531L12.6895 12.8031V17.9531H18.1895C18.8895 17.9531 19.4811 17.7115 19.9645 17.2281C20.4478 16.7448 20.6895 16.1531 20.6895 15.4531C20.6895 14.7531 20.4478 14.1615 19.9645 13.6781C19.4811 13.1948 18.8895 12.9531 18.1895 12.9531H16.6895V10.9531C16.6895 9.56979 16.2021 8.39046 15.2275 7.41512C14.2521 6.44046 13.0728 5.95312 11.6895 5.95312C10.3061 5.95312 9.12712 6.44046 8.15245 7.41512C7.17712 8.39046 6.68945 9.56979 6.68945 10.9531H6.18945C5.22279 10.9531 4.39779 11.2948 3.71445 11.9781C3.03112 12.6615 2.68945 13.4865 2.68945 14.4531C2.68945 15.4198 3.03112 16.2448 3.71445 16.9281C4.39779 17.6115 5.22279 17.9531 6.18945 17.9531H8.68945V19.9531H6.18945Z" fill="#51ABB3" />
            </svg>
        </button>
    )
}
