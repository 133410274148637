// Customizable Area Start
import React from "react";

import ChatsController, { ChatsProps } from "./ChatsController";
import { Box, BoxProps, IconButton, Popover, ThemeProvider, Typography, createTheme, styled } from "@material-ui/core";
import Sidebar from "../../../components/src/Sidebar.web";
import CustomAppHeader from "../../../components/src/CustomAppHeader.web";
import { MoreVert, Share } from "@material-ui/icons";

const theme = createTheme({
  typography: {
    h2: {
      fontFamily: "Cairo",
      fontWeight: 700,
      fontSize: 16,
      lineHeight: "28px",
      color: "#0F172A",
      '&.mt-8': {
        marginTop: 8,
      },
    },
    h3: {
      fontFamily: "Cairo",
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "22px",
      colr: "#334155",
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        padding: 4,
        height: 32,
        width: 32,
        boxSizing: "border-box",
        color: "#475569",
        '&:hover': {
          backgroundColor: "transparent",
        },
        '&[aria-expanded="true"]': {
          backgroundColor: "#0F172A8C",
          color: "#FFFFFF"
        },
      },
    },
    MuiPopover: {
      paper: {
        width: 156,
        maxWidth: 156,
        border: "1px solid #E2E8F0",
        backgroundColor: "#FFFFFF",
        padding: 4,
        borderRadius: 8,
        boxShadow: "0px 2px 8px 0px #00000014",
        '& .export-btn': {
          all: "unset",
          width: "100%",
          cursor: "pointer",
          padding: "9px 16px",
          boxSizing: "border-box",
          display: "flex",
          gap: 8,
          alignItems: "center",
          fontFamily: "Cairo",
          fontWeight: 400,
          fontSize: 14,
          lineHeight: "22px",
          color: "#0F172A"
        },
      },
    },
  },
})

const Container = styled("div")({
    display: "flex",
    backgroundColor: "#FAFAFA"
})

const ChatsContentContainer = styled("main")({
  boxSizing: "border-box",
  margin: "16px 48px 24px",
  backgroundColor: "#FFFFFF",
  borderRadius: 8,
  boxShadow: "0px 2px 8px 0px #00000014",
  display: "flex",
})

const ChatsListOuterContainer = styled("section")({
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  gap: 12,
  height: "calc(100vh - 132px)",
  overflowY: "auto",
  scrollbarWidth: "none",
  '&::-webkit-scrollbar': {
    width: 0,
  },
})

const ChatsTabButton = styled("button")({
  cursor: "pointer",
  boxSizing: "border-box",
  border: 0,
  backgroundColor: "transparent",
  textAlign: "center",
  fontSize: 16,
  lineHeight: "22px",
  fontWeight: 700,
  fontFamily: "Cairo",
  color: "#64748B",
  padding: "3px 12px",
  '&:focus': {
    outline: "none",
  },
  '&[aria-selected="true"]': {
    color: "#51ABB3",
    border: "1px solid #96CED3",
    backgroundColor: "#E5F1FF",
    borderRadius: 50,
  },
})

const ChatsTabs = styled("div")({
  '&.tabs': {
    backgroundColor: "#F8FAFC",
    borderRadius: 50,
    width: "max-content",
    position: "relative",
    display: "flex",
    padding: 4,
    boxSizing: "border-box",
  },
})

const ChatList = styled("div")({
  display: "flex",
  gap: 8,
  '& .icon': {
    width: 56,
    height: 56,
    boxSizing: "border-box",
    border: "2px solid #E5E6FF",
    backgroundColor: "#F0E5FF",
    borderRadius: 100,
    display: "grid",
    placeItems: "center",
    fontFamily: "Inter",
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: 700,
    color: "#A666FF",
  },
  '& .chatbot-detail-container': {
    padding: "8px 0",
    '& p': {
      margin: 0,
      fontFamily: "Cairo",
    },
    '& .bot-name': {
      color: "#475569",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "18px"
    },
    '& .user-name': {
      color: "#334155",
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "22px"
    },
  },
  '& .bot-time': {
    color: "#64748B",
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "18px",
    margin: "0 0 0 auto",
    paddingTop: 8,
    fontFamily: "Cairo",
  },
})

const ChatSeparator = styled("div")({
  borderTop: "1px solid #E2E8F0"
})

const ChatMessageContainer = styled("section")({
  display: "flex",
  flexDirection: "column",
  width: "67%",
})

const ChatMessageHeader = styled("div")({
  padding: "24px",
  display: "flex",
  justifyContent: "space-between",
  boxSizing: "border-box",
  borderBottom: "1px solid #CBD5E1",
  alignItems: "center",
  backgroundColor: "#FFFFFF",
})

const chatListBoxSx: BoxProps["sx"] = {
  boxSizing: "border-box",
  width: "33%",
  padding: "24px",
  borderRight: "1px solid #CBD5E1",
}

const chatTabsContainerSx: BoxProps["sx"] = {
  width: "100%",
  position: "sticky",
  top: 0,
  zIndex: 2
}

const ChatMessageLists = styled("div")({
  padding: "24px",
  display: "flex",
  flexDirection: "column",
  gap: 8,
  height: "calc(100vh - 190px)",
  overflowY: "auto",
  scrollbarWidth: "none",
  '&::-webkit-scrollbar': {
    width: 0,
  },
})

const Message = styled("div")({
  maxWidth: "50%",
  display: "inline-block",
  width: "max-content",
  padding: "12px 14px",
  boxSizing: "border-box",
  borderRadius: 12,
  backgroundColor: "#E2E8F0",
  fontFamily: "Cairo",
  fontWeight: 400,
  fontSize: 16,
  lineHeight: "24px",
  color: "#0F172A",
  '&[data-type="bot"]': {
    backgroundColor: "#C4E0E2",
    marginLeft: "auto",
  },
})

const ChatMessageTime = styled("div")({
  padding: "0px 16px",
  fontFamily: "Cairo",
  fontWeight: 400,
  fontSize: 12,
  lineHeight: "18px",
  color: "#94A3B8",
  '&[data-type="bot"]': {
    marginLeft: "auto",
  },
})


export default class Chats extends ChatsController {

  constructor(props: ChatsProps) {
      super(props)
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container>
            <Sidebar activeMenu="chats" navigation={this.props.navigation}/>
            <Box sx={{width: "100%"}} >
                <CustomAppHeader title="Chats" navigation={this.props.navigation} />
                <ChatsContentContainer>
                    <Box sx={chatListBoxSx} >
                    <ChatsListOuterContainer>
                      <Box sx={chatTabsContainerSx} style={{backgroundColor: "#FFFFFF", paddingBottom: 12}} >
                        <ChatsTabs className="tabs" >
                          <ChatsTabButton role="tab" aria-selected={this.state.isOpenChatTabActive} onClick={this.onToggleChatTab(true)} >Open Chats</ChatsTabButton>
                          <ChatsTabButton role="tab"  aria-selected={!this.state.isOpenChatTabActive} onClick={this.onToggleChatTab(false)} >Closed Chats</ChatsTabButton>
                        </ChatsTabs>
                      </Box>
                      <Typography variant="h2" className="mt-8" >Chats</Typography>
                      {
                        this.chatUsers.map((user, index) => (
                          <>
                            <ChatList>
                              <div className="icon" >C1</div>
                              <div className="chatbot-detail-container" >
                                <p className="user-name" >{user.name}</p>
                                <p className="bot-name" >{user.botName}</p>
                              </div>
                              <p className="bot-time">{user.time}</p>
                            </ChatList>
                            {index < this.chatUsers.length - 1 && <ChatSeparator />}
                          </>
                        ))
                      }
                      <ChatSeparator />
                    </ChatsListOuterContainer>
                    </Box>
                    <ChatMessageContainer>
                      <ChatMessageHeader>
                        <Typography variant="h3" >User #00001</Typography>
                        <IconButton aria-label="show export popover" aria-expanded={Boolean(this.state.exportAnchorEl)} onClick={this.onShowExportPopover} >
                          <MoreVert htmlColor="currentColor" />
                        </IconButton>
                      </ChatMessageHeader>
                      <ChatMessageLists>
                        <Message data-type="user" >Hello John!</Message>
                        <Message data-type="user" >Can you arrange schedule for next meeting?</Message>
                        <ChatMessageTime>09:45 PM</ChatMessageTime>
                        <Message data-type="bot" >Hello Jordan!</Message>
                        <Message data-type="bot" >{"Okay, I’ll arrange it soon. i noftify you when it’s done"}</Message>
                        <ChatMessageTime data-type="bot" >09:46 PM</ChatMessageTime>
                        <Message data-type="user" >Hello John!</Message>
                        <Message data-type="user" >Can you arrange schedule for next meeting?</Message>
                        <ChatMessageTime>09:45 PM</ChatMessageTime>
                        <Message data-type="bot" >Hello Jordan!</Message>
                        <Message data-type="bot" >{"Okay, I’ll arrange it soon. i noftify you when it’s done"}</Message>
                        <ChatMessageTime data-type="bot" >09:46 PM</ChatMessageTime>
                        <Message data-type="user" >Hello John!</Message>
                        <Message data-type="user" >Can you arrange schedule for next meeting?</Message>
                        <ChatMessageTime>09:45 PM</ChatMessageTime>
                        <Message data-type="bot" >Hello Jordan!</Message>
                        <Message data-type="bot" >{"Okay, I’ll arrange it soon. i noftify you when it’s done"}</Message>
                        <ChatMessageTime data-type="bot" >09:46 PM</ChatMessageTime>
                        <Message data-type="user" >Hello John!</Message>
                        <Message data-type="user" >Can you arrange schedule for next meeting?</Message>
                        <ChatMessageTime>09:45 PM</ChatMessageTime>
                        <Message data-type="bot" >Hello Jordan!</Message>
                        <Message data-type="bot" >{"Okay, I’ll arrange it soon. i noftify you when it’s done"}</Message>
                        <ChatMessageTime data-type="bot" >09:46 PM</ChatMessageTime>
                        <Message data-type="user" >Hello John!</Message>
                        <Message data-type="user" >Can you arrange schedule for next meeting?</Message>
                        <ChatMessageTime>09:45 PM</ChatMessageTime>
                        <Message data-type="bot" >Hello Jordan!</Message>
                        <Message data-type="bot" >{"Okay, I’ll arrange it soon. i noftify you when it’s done"}</Message>
                        <ChatMessageTime data-type="bot" >09:46 PM</ChatMessageTime>
                        <Message data-type="user" >Hello John!</Message>
                        <Message data-type="user" >Can you arrange schedule for next meeting?</Message>
                        <ChatMessageTime>09:45 PM</ChatMessageTime>
                        <Message data-type="bot" >Hello Jordan!</Message>
                        <Message data-type="bot" >{"Okay, I’ll arrange it soon. i noftify you when it’s done"}</Message>
                        <ChatMessageTime data-type="bot" >09:46 PM</ChatMessageTime>
                      </ChatMessageLists>
                    </ChatMessageContainer>
                </ChatsContentContainer>
            </Box>
        </Container>
        <Popover 
          id="export"
          open={Boolean(this.state.exportAnchorEl)}
          anchorEl={this.state.exportAnchorEl}
          onClose={this.onCloseExportPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <button className="export-btn" >
            <Share style={{height: 18, width: 18}} />
            Export
          </button>
        </Popover>
      </ThemeProvider>
    );
  }
}

// Customizable Area End
